<template>
    <div v-if="isLoadingScreen" class="fixed inset-0 flex items-center justify-center bg-black z-50">
        <div role="status">
            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="p-4 sm:ml-64">
        <div class="p-4">
            <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                            <li class="inline-flex items-center">
                                <a href="#"
                                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    {{ breadCrumb.root.text }}
                                </a>
                            </li>
                            <li v-for="items in breadCrumb.child" :key="items.text">
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <a href="#"
                                        class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">{{
                                            items.text
                                        }}</a>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    <div class="flex justify-end">
                        <button @click="createCommunity"
                            class="bg-blue-700 text-white px-2 py-1 rounded-md shadow-md hover:bg-gray-700 transition-colors duration-300">
                            <svg class="w-6 h-6 text-white-800 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Zm2 0V2h7a2 2 0 0 1 2 2v6.41A7.5 7.5 0 1 0 10.5 22H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Z"
                                    clip-rule="evenodd" />
                                <path fill-rule="evenodd"
                                    d="M9 16a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm6-3a1 1 0 0 1 1 1v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 1 1 0-2h1v-1a1 1 0 0 1 1-1Z"
                                    clip-rule="evenodd" />
                            </svg>

                        </button>
                        <button @click="trash"
                            class="bg-red-600  mx-1 text-white px-2 py-1 rounded-md shadow-md hover:bg-gray-700 transition-colors duration-300">
                            <svg class="w-6 h-6 text-white-800 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                    clip-rule="evenodd" />
                            </svg>
                        </button>

                    </div>

                    <div class="justify-start">
                        <form @submit.prevent="searchCommunity">
                            <div class="flex">

                                <div class="relative w-full">
                                    <input v-model="search" type="search" id="search-dropdown"
                                        class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-s-lg rounded-e-lg  border-s-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                                        placeholder="Search..." required />
                                    <button type="submit"
                                        class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                            fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                        <span class="sr-only">Search</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <button @click="resetCommunity"
                        class="max-w-10 bg-blue-700 text-white px-2 py-1 rounded-md shadow-md hover:bg-gray-700 transition-colors duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                    </button>
                </div>

                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Nick Name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Mou
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Pic
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Updated By
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white  dark:bg-gray-800 " v-for="item in community.content" :key="item.id">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 text-warp dark:text-white">
                                    {{ item.namaAgensi }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ item.namaSingkatanAgensi }}
                                </td>
                                <td class="px-2 py-4">
                                    <button @click="openModalImage(item.id, item.logoAgensi)"
                                        class="block text-white bg-white-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        type=" button">
                                        <svg class="w-6 h-6 text-blue-800 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd"
                                                d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-7Zm.394 9.553a1 1 0 0 0-1.817.062l-2.5 6A1 1 0 0 0 8 19h8a1 1 0 0 0 .894-1.447l-2-4A1 1 0 0 0 13.2 13.4l-.53.706-1.276-2.553ZM13 9.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                    <!-- Main modal -->
                                    <div class="fixed inset-0 bg-black z-50" v-if="isModalImageOpen">
                                        <div aria-hidden="true" class="fixed inset-0 flex items-center justify-center">
                                            <div class="relative p-4 w-full max-w-2xl">
                                                <!-- Modal content -->
                                                <div class="relative bg-white rounded-lg shadow">
                                                    <!-- Modal header -->
                                                    <div
                                                        class="flex items-center justify-between p-4 border-b rounded-t">
                                                        <h3 class="text-xl font-semibold text-gray-900">Image</h3>
                                                        <span v-if="isLoading === false">
                                                            <button type="button" @click="closeModalImage"
                                                                class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                                                <svg class="w-3 h-3" aria-hidden="true" fill="none"
                                                                    viewBox="0 0 14 14">
                                                                    <path stroke="currentColor" stroke-linecap="round"
                                                                        stroke-linejoin="round" stroke-width="2"
                                                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                                </svg>
                                                                <span class="sr-only">Close modal</span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                    <!-- Modal body -->
                                                    <div class="p-4 space-y-4">
                                                        <div>
                                                            <div class="max-h-full">
                                                                <label class="cursor-pointer">
                                                                    <div v-if="isLoading"
                                                                        class="flex items-center justify-center w-full h-32">
                                                                        <svg class="animate-spin h-10 w-10 text-gray-500"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none" viewBox="0 0 24 24">
                                                                            <circle class="opacity-25" cx="12" cy="12"
                                                                                r="10" stroke="currentColor"
                                                                                stroke-width="4"></circle>
                                                                            <path class="opacity-75" fill="currentColor"
                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4v4a4 4 0 00-4 4H6v4.291zM20 12a8 8 0 01-8 8v-4a4 4 0 004-4h4zm-2-5.709A8.001 8.001 0 0112 20V16a4 4 0 004-4h4V6.291z">
                                                                            </path>
                                                                        </svg>
                                                                    </div>
                                                                    <img v-else class="h-[500px] w-full rounded-lg"
                                                                        :src="url_image" alt="image description">
                                                                    <input accept="image/png, image/jpeg"
                                                                        @change="onFileSelectedImage" type="file"
                                                                        class="hidden" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Modal footer -->
                                                    <div class="flex items-center p-4 border-t rounded-b">
                                                        <span v-if="this.imageUpload !== null">
                                                            <button @click="editGambarCommunity" type="button"
                                                                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                Update
                                                            </button>
                                                        </span>
                                                        <span v-else>
                                                            <button disabled="true" type="button"
                                                                class="text-white bg-gray-400 hover:bg-gray-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-400 dark:hover:bg-gray-400 dark:focus:ring-gray-400">
                                                                Update
                                                            </button>
                                                        </span>
                                                        <span v-if="isLoading === false">
                                                            <button type="button" @click="closeModalImage"
                                                                class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                                Decline
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-2 py-4">
                                    <button @click="openModalMou(item.id, item.mou)"
                                        class="block text-white bg-white-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        type=" button">
                                        <svg class="w-6 h-6 text-blue-800 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd"
                                                d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-7Z"
                                                clip-rule="evenodd" />
                                        </svg>

                                    </button>
                                    <!-- Main modal -->
                                    <div class="fixed inset-0 bg-black z-50" v-if="isModalMouOpen">
                                        <div aria-hidden="true" class="fixed inset-0 flex items-center justify-center">
                                            <div class="relative p-4 w-full max-w-2xl">
                                                <!-- Modal content -->
                                                <div class="relative bg-white rounded-lg shadow">
                                                    <!-- Modal header -->
                                                    <div
                                                        class="flex items-center justify-between p-4 border-b rounded-t">
                                                        <h3 class="text-xl font-semibold text-gray-900">MOU</h3>
                                                        <span v-if="isLoadingMou === false">
                                                            <button type="button" @click="closeModalMou"
                                                                class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                                                <svg class="w-3 h-3" aria-hidden="true" fill="none"
                                                                    viewBox="0 0 14 14">
                                                                    <path stroke="currentColor" stroke-linecap="round"
                                                                        stroke-linejoin="round" stroke-width="2"
                                                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                                </svg>
                                                                <span class="sr-only">Close modal</span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                    <!-- Modal body -->
                                                    <div class="p-4 space-y-4">
                                                        <div>
                                                            <div class="max-h-full">
                                                                <label class="cursor-pointer">
                                                                    <div v-if="isLoadingMou"
                                                                        class="flex items-center justify-center w-full h-32">
                                                                        <svg class="animate-spin h-10 w-10 text-gray-500"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none" viewBox="0 0 24 24">
                                                                            <circle class="opacity-25" cx="12" cy="12"
                                                                                r="10" stroke="currentColor"
                                                                                stroke-width="4"></circle>
                                                                            <path class="opacity-75" fill="currentColor"
                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4v4a4 4 0 00-4 4H6v4.291zM20 12a8 8 0 01-8 8v-4a4 4 0 004-4h4zm-2-5.709A8.001 8.001 0 0112 20V16a4 4 0 004-4h4V6.291z">
                                                                            </path>
                                                                        </svg>
                                                                    </div>
                                                                    <div v-else>
                                                                        <embed id="mouEmbed" :src="url_mou"
                                                                            class="w-full h-[400px]" />
                                                                        <input accept="application/pdf"
                                                                            @change="onFileSelectedMou" type="file"
                                                                            class="block my-1 w-full text-sm text-gray-900 border border-gray-300  cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Modal footer -->
                                                    <div class="flex items-center p-4 border-t rounded-b">
                                                        <span v-if="this.mouUpload !== null">
                                                            <button @click="editMouCommunity" type="button"
                                                                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                Update
                                                            </button>
                                                        </span>
                                                        <span v-else>
                                                            <button disabled="true" type="button"
                                                                class="text-white bg-gray-400 hover:bg-gray-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-400 dark:hover:bg-gray-400 dark:focus:ring-gray-400">
                                                                Update
                                                            </button>
                                                        </span>
                                                        <span v-if="isLoadingMou === false">
                                                            <button type="button" @click="closeModalMou"
                                                                class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                                Decline
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-2 py-4">
                                    <button @click="addPicCommunity(item.id, item.namaAgensi)"
                                        class="block text-white bg-white-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        type=" button">
                                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd"
                                                d="M9 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H7Zm8-1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                </td>
                                <td class="px-6 py-4">
                                    {{ item.updatedBy }}
                                </td>
                                <td class="flex px-6 py-4">
                                    <span class="mr-2">
                                        <button @click="editCommunity(item.id)"
                                            class="block text-white bg-white-700  font-medium rounded-lg text-sm py-2.5 text-center"
                                            type=" button">
                                            <span>
                                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    fill="currentColor" viewBox="0 0 24 24">
                                                    <path fill-rule="evenodd"
                                                        d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z"
                                                        clip-rule="evenodd" />
                                                    <path fill-rule="evenodd"
                                                        d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                        </button>
                                    </span>
                                    <span>
                                        <button @click="setActiveStatusCommunity(item.id)"
                                            class="block text-white bg-white-700  font-medium rounded-lg text-sm py-2.5 text-center"
                                            type=" button">
                                            <span>
                                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    fill="currentColor" viewBox="0 0 24 24">
                                                    <path fill-rule="evenodd"
                                                        d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                        </button>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <nav class="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
                    aria-label="Table navigation">
                    <span
                        class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">Showing
                        <span class="font-semibold text-gray-900 dark:text-white">
                            {{ (pageInfo.pageNumber - 1) * pageInfo.pageSize + 1 }} -
                            {{ Math.min(pageInfo.pageNumber * pageInfo.pageSize, pageInfo.totalElements) }}
                        </span> of <span class="font-semibold text-gray-900 dark:text-white">{{ pageInfo.totalElements
                            }}</span></span>
                    <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                        <li v-if="this.pageInfo.pageNumber > 1"> <a href="#" @click="goToPreviousPage"
                                class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                Previous</a>
                        </li>
                        <li v-for="pageNumber in visiblePages" :key="pageNumber">
                            <a href="#" aria-current="page" @click="goToPage(pageNumber)"
                                :class="{ 'bg-blue-700 text-white': pageNumber === pageInfo.pageNumber }"
                                class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{
                                    pageNumber }}</a>
                        </li>
                        <li>
                            <a href="#" @click="goToNextPage"
                                class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            url_image: require("@/assets/image-1@2x.jpg"),
            url_mou: null,
            isModalImageOpen: false,
            isModalMouOpen: false,
            isLoading: false,
            isLoadingMou: false,
            isLoadingScreen: false,
            clickedItemId: null,
            imageUpload: null,
            mouUpload: null,
            search: "",
            agensi: null,
            breadCrumb: {
                root: { text: "Home", url: "" },
                child: [
                    { text: "Community", url: "" },
                    { text: "List", url: "" }
                ]
            },
            pageInfo: {
                totalPages: 0,
                pageNumber: 0,
                visiblePages: 5,
                pageSize: 5,
                totalElements: 0,
                isSearch: false,
            }
        }
    },
    created() {
        var parameters = this.$route.params
        this.fetchCommunityData(parameters.agensi, parseInt(parameters.pageNumber), parameters.isSearch, parameters.search)
    },
    computed: {
        community() {
            return this.$store.getters.getAllAgensi
        },
        visiblePages() {
            const activePage = this.pageInfo.pageNumber;
            const totalVisiblePages = this.pageInfo.visiblePages;
            const totalPages = this.pageInfo.totalPages;

            let startPage = activePage - Math.floor(totalVisiblePages / 2)
            startPage = Math.max(startPage, 1)
            let endPage = startPage + totalVisiblePages - 1
            endPage = Math.min(endPage, totalPages)

            if (endPage === totalPages && totalPages - totalVisiblePages + 1 > 0) {
                startPage = totalPages - totalVisiblePages + 1
            }

            let visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)

            if (endPage < totalPages) {
                visiblePages.push(endPage + 1)
            }

            return visiblePages;
        }
    },
    methods: {
        async fetchCommunityData(agensi, pageNumber, isSearch, search) {
            this.pageInfo.pageNumber = pageNumber
            this.pageInfo.isSearch = isSearch
            this.agensi = agensi
            await this.$store.dispatch('allListAgensi', {
                isSearch: this.pageInfo.isSearch,
                search: search,
                statusAktif: 1,
                index: (this.pageInfo.pageNumber - 1),
                limit: this.pageInfo.pageSize,
            }).then(() => {
                this.isLoadingScreen = true
                this.pageInfo.totalElements = this.community.totalElements
                this.pageInfo.totalPages = this.community.totalPages
            }).finally(() => {
                this.isLoadingScreen = false
            })
        },
        async resetCommunity() {
            this.search = ""
            await this.fetchCommunityData(this.agensi, 1, false, this.search)
            this.tempRouteListCommunity(this.agensi, this.pageInfo.isSearch, this.pageInfo.pageNumber)
        },
        async searchCommunity() {
            await this.fetchCommunityData(this.agensi, 1, true, this.search)
            this.tempRouteListSearchCommunity(this.agensi, this.pageInfo.isSearch, this.pageInfo.pageNumber, this.search)
        },
        async goToPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.pageInfo.totalPages) {
                await this.fetchCommunityData(this.agensi, pageNumber, this.pageInfo.isSearch, this.search)
                if (this.pageInfo.isSearch === true) {
                    this.tempRouteListSearchCommunity(this.agensi, this.pageInfo.isSearch, pageNumber, this.search)
                } else {
                    this.tempRouteListCommunity(this.agensi, this.pageInfo.isSearch, pageNumber)
                }
            }
        },
        async goToNextPage() {
            const nextPageNumber = this.pageInfo.pageNumber + 1;
            if (nextPageNumber <= this.pageInfo.totalPages) {
                await this.fetchCommunityData(this.agensi, nextPageNumber, this.pageInfo.isSearch, this.search)
                if (this.pageInfo.isSearch === true) {
                    this.tempRouteListSearchCommunity(this.agensi, this.pageInfo.isSearch, nextPageNumber, this.search)
                } else {
                    this.tempRouteListCommunity(this.agensi, this.pageInfo.isSearch, nextPageNumber)
                }
            }
        },
        async goToPreviousPage() {
            const previousPageNumber = this.pageInfo.pageNumber - 1
            if (previousPageNumber >= 1) {
                await this.fetchCommunityData(this.agensi, previousPageNumber, this.pageInfo.isSearch, this.search);
                if (this.pageInfo.isSearch === true) {
                    this.tempRouteListSearchCommunity(this.agensi, this.pageInfo.isSearch, previousPageNumber, this.search)
                } else {
                    this.tempRouteListCommunity(this.agensi, this.pageInfo.isSearch, previousPageNumber)
                }
            }
        },
        tempRouteListSearchCommunity(agensi, isSearch, pageNumber, search) {
            this.$router.push({ name: 'list-community-search', params: { agensi: agensi, isSearch: isSearch, pageNumber: pageNumber, search: search.replace(/\s+/g, '-') } })
        },
        tempRouteListCommunity(agensi, isSearch, pageNumber) {
            this.$router.push({ name: 'list-community', params: { agensi: agensi, isSearch: isSearch, pageNumber: pageNumber } })
        },
        openModalImage(itemId, itemGambar) {
            this.clickedItemId = itemId
            this.isLoading = true
            this.url_image = itemGambar || null
            this.isModalImageOpen = true

            const imgElement = new Image()
            imgElement.src = itemGambar

            imgElement.addEventListener('load', () => {
                this.isLoading = false;
            })

            imgElement.addEventListener('error', () => {
                this.isLoading = false;
                console.error('Error loading image')
            })
        },
        closeModalImage() {
            this.isModalImageOpen = false
            this.imageUpload = null
        },
        onFileSelectedImage(event) {
            const inputElement = event.target
            const file = inputElement.files?.[0]
            this.url_image = file ? URL.createObjectURL(file) : ""
            this.imageUpload = file ? file : ""
        },
        editGambarCommunity() {
            if (this.imageUpload.size > (1024 * 1024)) {
                Swal.fire({
                    title: "Warning",
                    text: "Image Size More Than 1 MB",
                    icon: "warning"
                })
            } else {
                Swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Edit",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            this.$store.dispatch('editGambarAgensi', {
                                id: this.clickedItemId,
                                gambarAgensi: this.imageUpload
                            }).then(() => {
                                Swal.fire({
                                    title: "Edit Success",
                                    text: "Sukses Edit Gambar Komunitas!",
                                    icon: "success"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.isLoading = false
                                        this.isModalImageOpen = false
                                        location.reload()
                                    }
                                })
                            }).catch((error) => {
                                Swal.fire({
                                    title: "Error",
                                    text: "Gagal Edit Gambar Komunitas: " + error,
                                    icon: "error"
                                })
                            })
                        }
                    })
            }
        },
        editMouCommunity() {
            if (this.mouUpload.size > (1024 * 1024)) {
                Swal.fire({
                    title: "Warning",
                    text: "File Size More Than 1 MB",
                    icon: "warning"
                })
            } else {
                Swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Edit",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoadingMou = true
                            this.$store.dispatch('editMouAgensi', {
                                id: this.clickedItemId,
                                mouAgensi: this.mouUpload
                            }).then(() => {
                                Swal.fire({
                                    title: "Edit Success",
                                    text: "Sukses Edit MOU Komunitas!",
                                    icon: "success"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.isLoadingMou = false
                                        this.isModalImageOpen = false
                                        location.reload()
                                    }
                                })
                            }).catch((error) => {
                                Swal.fire({
                                    title: "Error",
                                    text: "Gagal Edit MOU Komunitas: " + error,
                                    icon: "error"
                                })
                            })
                        }
                    })
            }
        },
        onFileSelectedMou(event) {
            const inputElement = event.target
            const file = inputElement.files?.[0]
            this.url_mou = file ? URL.createObjectURL(file) : ""
            this.mouUpload = file ? file : ""
        },
        openModalMou(itemId, itemMou) {
            this.isLoadingMou = true
            this.clickedItemId = itemId
            this.url_mou = itemMou || null
            this.isModalMouOpen = true

            setTimeout(() => {
                this.isLoadingMou = false
            }, 2000)
        },
        closeModalMou() {
            this.isModalMouOpen = false
            this.mouUpload = null
        },
        setActiveStatusCommunity(id) {
            Swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Delete",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoadingScreen = true
                        this.$store.dispatch('setStatusAktifAgensi', {
                            id: id,
                            statusAktif: false
                        }).then(() => {
                            Swal.fire({
                                title: "Delete Success",
                                text: "Sukses Hapus Komunitas!",
                                icon: "success"
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.isLoadingScreen = false
                                    location.reload()
                                }
                            })
                        }).catch((error) => {
                            Swal.fire({
                                title: "Error",
                                text: "Gagal Hapus Komunitas: " + error,
                                icon: "error"
                            })
                        })
                    }
                })
        },
        addPicCommunity(id, name) {
            this.$router.push({ name: 'list-pic-community', params: { agensi: this.$route.params.agensi, idCommunity: id, isSearch: false, pageNumber: 1, communityName: name } })
        },
        trash() {
            this.$router.push({ name: 'list-trash-community', params: { agensi: this.$route.params.agensi, isSearch: false, pageNumber: 1 } })
        },
        createCommunity() {
            this.$router.push({ name: 'create-community', params: { agensi: this.$route.params.agensi } })
        },
        editCommunity(id) {
            this.$router.push({ name: 'edit-community', params: { agensi: this.$route.params.agensi, idCommunity: id } })
        },
    }
}
</script>