<template>
    <div class="flex min-h-full flex-1 flex-col justify-center  px-6 py-12 lg:px-8">
        <span v-if="agensi && agensi.content && agensi.content.length !== 0">
            <div
                class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-red-500 py-1.5 px-3 font-sans text-xs font-bold uppercase text-white max-w-40 my-5">
                <span class="">MANAGEMENT</span>
            </div>
            <div class="grid gap-6 mb-6 md:grid-cols-5">
                <span v-for="item in agensi.content" :key="item.id">
                    <div
                        class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 flex flex-col h-full">
                        <a href="#" class="flex justify-center">
                            <img class="rounded-t-lg w-fit h-40" :src="item.logoAgensi" alt="" />
                        </a>
                        <div class="p-5">
                            <a href="#">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{
                                    item.namaAgensi.toUpperCase() }}</h5>
                            </a>
                            <button @click="goToSuperAdmin(item)"
                                class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Go To
                                <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </span>
            </div>
            <div class="my-4">
                <hr>
            </div>
        </span>
        <span v-if="komunitas && komunitas.content && komunitas.content.length !== 0">
            <div
                class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-blue-500 py-1.5 px-3 font-sans text-xs font-bold uppercase text-white max-w-40 my-5">
                <span class="">Komunitas</span>
            </div>
            <div class="grid gap-6 mb-6 md:grid-cols-5">
                <span v-for="item in komunitas.content" :key="item.id">
                    <div
                        class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 flex flex-col h-full">
                        <a href="#" class="flex justify-center">
                            <img class="rounded-t-lg w-fit h-40" :src="item.logoAgensi" alt="" />
                        </a>
                        <div class="p-5 flex flex-col justify-between flex-grow">
                            <div>
                                <a href="#">
                                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{
                                        item.namaAgensi.toUpperCase() }}</h5>
                                </a>
                            </div>
                            <div class="p-5">
                                <button @click="goToKomunitas(item)"
                                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Go To
                                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
            <div class="flex flex-col items-center">
                <span class="text-sm text-gray-700 dark:text-gray-400">
                    Showing <span class="font-semibold text-gray-900 dark:text-white">{{ (pageInfoKomunitas.pageNumber -
                        1) *
                        pageInfoKomunitas.pageSize + 1 }}</span> to <span
                        class="font-semibold text-gray-900 dark:text-white">{{ Math.min(pageInfoKomunitas.pageNumber *
                            pageInfoKomunitas.pageSize, pageInfoKomunitas.totalElements) }}</span> of <span
                        class="font-semibold text-gray-900 dark:text-white">{{ pageInfoKomunitas.totalElements
                        }}</span> Entries
                </span>
                <div class="inline-flex mt-2 xs:mt-0">
                    <button type="button" @click="goToPreviousPageKomunitas"
                        class="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        Prev
                    </button>
                    <button type="button" @click="goToNextPageKomunitas"
                        class="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        Next
                    </button>
                </div>
            </div>
            <div class="my-4">
                <hr>
            </div>
        </span>
        <span v-if="subKomunitas && subKomunitas.content && subKomunitas.content.length !== 0">
            <div
                class="relative grid select-none items-center whitespace-nowrap rounded-lg bg-green-500 py-1.5 px-3 font-sans text-xs font-bold uppercase text-white max-w-40 my-5">
                <span class="">Sub Komunitas</span>
            </div>
            <div class="grid gap-6 mb-6 md:grid-cols-5">
                <span v-for="item in subKomunitas.content" :key="item.id">
                    <div
                        class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 flex flex-col h-full">
                        <a href="#" class="flex justify-center">
                            <img class="rounded-t-lg w-fit h-40" :src="item.gambarSubKomunitas" alt="" />
                        </a>
                        <div class="p-5 flex flex-col justify-between flex-grow">
                            <div>
                                <label class="text-gray-400">From: {{ item.agensi }}</label>
                            </div>
                            <div>
                                <a href="#">
                                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{
                                        item.namaSubKomunitas.toUpperCase() }}</h5>
                                </a>
                            </div>
                            <div>
                                <button @click="goToSubKomunitas(item)"
                                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Go To
                                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
            <div class="flex flex-col items-center">
                <span class="text-sm text-gray-700 dark:text-gray-400">
                    Showing <span class="font-semibold text-gray-900 dark:text-white">{{
                        (pageInfoSubKomunitas.pageNumber - 1) * pageInfoSubKomunitas.pageSize + 1 }}</span> to <span
                        class="font-semibold text-gray-900 dark:text-white">{{ Math.min(pageInfoSubKomunitas.pageNumber
                            * pageInfoSubKomunitas.pageSize, pageInfoSubKomunitas.totalElements) }}</span> of <span
                        class="font-semibold text-gray-900 dark:text-white">{{ pageInfoSubKomunitas.totalElements
                        }}</span> Entries
                </span>
                <div class="inline-flex mt-2 xs:mt-0">
                    <button type="button" @click="goToPreviousPageSubKomunitas"
                        class="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        Prev
                    </button>
                    <button type="button" @click="goToNextPageSubKomunitas"
                        class="flex items-center justify-center px-3 h-8 text-sm font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        Next
                    </button>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            url_image: require("@/assets/image-1@2x.jpg"),
            pageInfo: {
                totalPages: 0,
                pageNumber: 0,
                visiblePages: 5,
                pageSize: 5,
                totalElements: 0,
            },
            pageInfoKomunitas: {
                totalPages: 0,
                pageNumber: 0,
                visiblePages: 5,
                pageSize: 5,
                totalElements: 0,
            },
            pageInfoSubKomunitas: {
                totalPages: 0,
                pageNumber: 0,
                visiblePages: 5,
                pageSize: 5,
                totalElements: 0,
            }
        }
    },
    created() {
        // var parameters = this.$route.params
        this.agensiData(1, this.pageInfo.pageNumber, this.pageInfo.pageSize)
        this.komunitasData(1, this.pageInfoKomunitas.pageNumber, this.pageInfoKomunitas.pageSize)
        this.subKomunitasData(1, this.pageInfoSubKomunitas.pageNumber, this.pageInfoSubKomunitas.pageSize)
    },
    computed: {
        userDataLogin() {
            return this.$store.getters.getUserDataLogin
        },
        dataUser() {
            const data = sessionStorage.getItem('userData')
            const jsonParse = JSON.parse(data)
            return jsonParse
        },
        agensi() {
            return this.$store.getters.getAgensi
        },
        getUsersRoleAgensi() {
            return this.$store.getters.getUsersRoleAgensi
        },
        komunitas() {
            return this.$store.getters.getKomunitas
        },
        subKomunitas() {
            return this.$store.getters.getSubKomunitas
        },
    },
    methods: {
        async goToNextPageKomunitas() {
            const nextPageNumber = this.pageInfoKomunitas.pageNumber + 1;
            if (nextPageNumber <= this.pageInfoKomunitas.totalPages) {
                await this.komunitasData(nextPageNumber)
            }
        },
        async goToPreviousPageKomunitas() {
            const previousPageNumber = this.pageInfoKomunitas.pageNumber - 1
            if (previousPageNumber >= 1) {
                await this.komunitasData(previousPageNumber)
            }
        },
        async goToNextPageSubKomunitas() {
            const nextPageNumber = this.pageInfoSubKomunitas.pageNumber + 1;
            if (nextPageNumber <= this.pageInfoSubKomunitas.totalPages) {
                await this.subKomunitasData(nextPageNumber)
            }
        },
        async goToPreviousPageSubKomunitas() {
            const previousPageNumber = this.pageInfoSubKomunitas.pageNumber - 1
            if (previousPageNumber >= 1) {
                await this.subKomunitasData(previousPageNumber)
            }
        },
        async agensiData(pageNumber) {
            this.pageInfo.pageNumber = pageNumber
            await this.$store.dispatch('listAgensi', {
                index: (this.pageInfo.pageNumber - 1),
                limit: this.pageInfo.pageSize,
            })
            this.pageInfo.totalElements = this.agensi.totalElements
            this.pageInfo.totalPages = this.agensi.totalPages
        },
        async komunitasData(pageNumber) {
            this.pageInfoKomunitas.pageNumber = pageNumber
            await this.$store.dispatch('listKomunitas', {
                index: (this.pageInfoKomunitas.pageNumber - 1),
                limit: this.pageInfoKomunitas.pageSize,
            })
            this.pageInfoKomunitas.totalElements = this.komunitas.totalElements
            this.pageInfoKomunitas.totalPages = this.komunitas.totalPages
        },
        async subKomunitasData(pageNumber) {
            this.pageInfoSubKomunitas.pageNumber = pageNumber
            await this.$store.dispatch('listSubKomunitas', {
                index: (this.pageInfoSubKomunitas.pageNumber - 1),
                limit: this.pageInfoSubKomunitas.pageSize,
            })
            this.pageInfoSubKomunitas.totalElements = this.subKomunitas.totalElements
            this.pageInfoSubKomunitas.totalPages = this.subKomunitas.totalPages
        },
        goToSuperAdmin(item) {
            this.checkUsersRoleAgensi(item)
        },
        async checkUsersRoleAgensi(item) {
            console.log(item.id)
            await this.$store.dispatch('checkUsersRoleAgensi', {
                idAgensi: item.id
            })
            sessionStorage.setItem("agensi", JSON.stringify(item))
            if (this.getUsersRoleAgensi.idRoleLink === 1) {
                sessionStorage.setItem("role", "superadmin")
            } else {
                sessionStorage.setItem("role", "admin1")
            }
            this.$store.dispatch('login', {
                email: this.dataUser.email
            }).then(() => {
                sessionStorage.setItem("userData", JSON.stringify(this.userDataLogin.data))
            })
            this.$router.push({ name: 'dashboard', params: { idAgensi: item.id, agensi: item.namaAgensi.replace(/\s+/g, '-'), } })
        },
        goToKomunitas(item) {
            sessionStorage.setItem("role", "admin")
            sessionStorage.setItem("agensi", JSON.stringify(item))
            this.$router.push({ name: 'dashboard', params: { idAgensi: item.id, agensi: item.namaAgensi.replace(/\s+/g, '-'), } })
        },
        goToSubKomunitas(item) {
            sessionStorage.setItem("role", "adminSubKomunitas")
            sessionStorage.setItem("subKomunitas", JSON.stringify(item))
            this.$router.push({ name: 'dashboard-sub-komunitas', params: { idSubKomunitas: item.id, subKomunitas: item.namaSubKomunitas.replace(/\s+/g, '-'), } })
        }
    }
}
</script>
