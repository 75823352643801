<template>
  <span v-if="$route.name != 'login' && $route.name != 'role'">
    <SideBar />
  </span>
  <router-view />
</template>

<script>
import SideBar from "@/components/SideBar.vue"
export default {
  name: "App",
  data() {
    return {}
  },
  components: {
    SideBar
  }
}
</script>
