<template>
    <div v-if="isLoadingScreen" class="fixed inset-0 flex items-center justify-center bg-black z-50">
        <div role="status">
            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="p-4 sm:ml-64">
        <div class="p-4">
            <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                            <li class="inline-flex items-center">
                                <a href="#"
                                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    {{ breadCrumb.root.text }}
                                </a>
                            </li>
                            <li v-for="items in breadCrumb.child" :key="items.text">
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <a href="#"
                                        class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">{{
                                            items.text
                                        }}</a>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    <div class="flex justify-end">
                        <button @click="openModalCreate"
                            class="bg-blue-700 text-white px-2 py-1 rounded-md shadow-md hover:bg-gray-700 transition-colors duration-300">
                            <svg class="w-6 h-6 text-white-800 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Zm2 0V2h7a2 2 0 0 1 2 2v6.41A7.5 7.5 0 1 0 10.5 22H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Z"
                                    clip-rule="evenodd" />
                                <path fill-rule="evenodd"
                                    d="M9 16a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm6-3a1 1 0 0 1 1 1v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 1 1 0-2h1v-1a1 1 0 0 1 1-1Z"
                                    clip-rule="evenodd" />
                            </svg>
                        </button>
                        <div class="fixed inset-0 bg-black z-50" v-if="isModalCreateOpen">
                            <form @submit.prevent="create" @keydown.enter.prevent="">
                                <div aria-hidden="true" class="fixed inset-0 flex items-center justify-center">
                                    <div class="relative p-4 ">
                                        <!-- Modal content -->
                                        <div class="relative bg-white rounded-lg shadow">
                                            <!-- Modal header -->
                                            <div class="flex items-center justify-between p-4 border-b rounded-t">
                                                <h3 class="text-xl font-semibold text-gray-900">Create Slider</h3>
                                                <button type="button" @click="closeModalCreate"
                                                    class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                                    <svg class="w-3 h-3" aria-hidden="true" fill="none"
                                                        viewBox="0 0 14 14">
                                                        <path stroke="currentColor" stroke-linecap="round"
                                                            stroke-linejoin="round" stroke-width="2"
                                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                    </svg>
                                                    <span class="sr-only">Close modal</span>
                                                </button>
                                            </div>
                                            <!-- Modal body -->
                                            <div class="p-4 space-y-4">
                                                <div class="grid gap-6 mb-6 md:grid-cols-3">
                                                    <div>
                                                        <label for="title"
                                                            class="block mb-2 text-sm font-medium text-gray-900">Title</label>
                                                        <input v-model="title" type="text" id="title"
                                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                                            placeholder="Slider Title" required />
                                                    </div>
                                                    <div>
                                                        <label for="first_name"
                                                            class="block mb-2 text-sm font-medium text-gray-900">Start
                                                            Date</label>
                                                        <div class="relative my-2.5">
                                                            <VueDatePicker v-model="startDate" :format="dateFormat">
                                                            </VueDatePicker>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label for="first_name"
                                                            class="block mb-2 text-sm font-medium text-gray-900">End
                                                            Date</label>
                                                        <div class="relative my-2.5">
                                                            <VueDatePicker v-model="endDate" :format="dateFormat">
                                                            </VueDatePicker>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="grid gap-6 mb-6 md:grid-cols-3">
                                                    <div>
                                                        <label for="link_url"
                                                            class="block mb-2 text-sm font-medium text-gray-900">Url
                                                            Link</label>
                                                        <input v-model="link_url" type="text" id="title"
                                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                                            placeholder="Url Link" required />
                                                    </div>
                                                    <div>
                                                        <label for="first_name"
                                                            class="block mb-2 text-sm font-medium text-gray-900">Default
                                                            slider (1224 x 400 Pixels)</label>
                                                        <div class="max-h-full">
                                                            <label class="cursor-pointer">
                                                                <img class="w-[500px] h-[100px] rounded-lg"
                                                                    :src="url_image" alt="image description">
                                                                <input accept="image/png, image/jpeg"
                                                                    @change="onFileSelectedImageCreate" type="file"
                                                                    class="hidden" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label for="first_name"
                                                            class="block mb-2 text-sm font-medium text-gray-900">Mobile
                                                            slider (328 x 328 Pixels)</label>
                                                        <div class="max-h-full">
                                                            <label class="cursor-pointer">
                                                                <img class="w-[100px] h-[100px] rounded-lg"
                                                                    :src="url_image_mobile" alt="image description">
                                                                <input accept="image/png, image/jpeg"
                                                                    @change="onFileSelectedImageMobileCreate"
                                                                    type="file" class="hidden" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Modal footer -->
                                            <div class="flex items-center p-4 border-t rounded-b">
                                                <button type="submit"
                                                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                    Create
                                                </button>
                                                <button type="button" @click="closeModalCreate"
                                                    class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                    Decline
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Title
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Start Date
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    End Date
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Default Slider
                                </th>
                                <th scope="col" class="px-2 py-3">
                                    Mobile Slider
                                </th>
                                <th scope="col" class="px-2 py-3">
                                    Position
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :class="{ 'bg-red-500 text-white': !item.publish, 'bg-white dark:bg-gray-800': item.publish }"
                                v-for="item in getListSlider.content" :key="item.id">
                                <td scope="row" class="px-6 py-4 font-mediu text-warp dark:text-white">
                                    {{ item.judulSlider }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ formatDate(item.startDate) }}
                                </td>
                                <td class="px-6 py-4 ">
                                    {{ formatDate(item.endDate) }}
                                </td>
                                <td class="px-8 py-4">
                                    <button @click="openModalImage(item.id, item.defaultSlider)"
                                        class="block text-white bg-white-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        type=" button">
                                        <svg class="w-6 h-6 text-blue-800 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd"
                                                d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-7Zm.394 9.553a1 1 0 0 0-1.817.062l-2.5 6A1 1 0 0 0 8 19h8a1 1 0 0 0 .894-1.447l-2-4A1 1 0 0 0 13.2 13.4l-.53.706-1.276-2.553ZM13 9.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                    <!-- Main modal -->
                                    <div class="fixed inset-0 bg-black z-50" v-if="isModalImageOpen">
                                        <div aria-hidden="true" class="fixed inset-0 flex items-center justify-center">
                                            <div class="relative p-4 ">
                                                <!-- Modal content -->
                                                <div class="relative bg-white rounded-lg shadow">
                                                    <!-- Modal header -->
                                                    <div
                                                        class="flex items-center justify-between p-4 border-b rounded-t">
                                                        <h3 class="text-xl font-semibold text-gray-900">Slider (1224 x
                                                            400 Pixels)</h3>
                                                        <button type="button" @click="closeModalImage"
                                                            class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                                            <svg class="w-3 h-3" aria-hidden="true" fill="none"
                                                                viewBox="0 0 14 14">
                                                                <path stroke="currentColor" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2"
                                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                            </svg>
                                                            <span class="sr-only">Close modal</span>
                                                        </button>
                                                    </div>
                                                    <!-- Modal body -->
                                                    <div class="p-4 space-y-4">
                                                        <div>
                                                            <div class="max-h-full">
                                                                <label class="cursor-pointer">
                                                                    <div v-if="isLoading"
                                                                        class="flex items-center justify-center w-full h-32">
                                                                        <svg class="animate-spin h-10 w-10 text-gray-500"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none" viewBox="0 0 24 24">
                                                                            <circle class="opacity-25" cx="12" cy="12"
                                                                                r="10" stroke="currentColor"
                                                                                stroke-width="4"></circle>
                                                                            <path class="opacity-75" fill="currentColor"
                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4v4a4 4 0 00-4 4H6v4.291zM20 12a8 8 0 01-8 8v-4a4 4 0 004-4h4zm-2-5.709A8.001 8.001 0 0112 20V16a4 4 0 004-4h4V6.291z">
                                                                            </path>
                                                                        </svg>
                                                                    </div>
                                                                    <img v-else class="w-[1224px] h-[400px]  rounded-lg"
                                                                        :src="url_image" alt="image description">
                                                                    <input accept="image/png, image/jpeg"
                                                                        @change="onFileSelectedImage" type="file"
                                                                        class="hidden" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Modal footer -->
                                                    <div class="flex items-center p-4 border-t rounded-b">
                                                        <span v-if="this.imageUpload !== null">
                                                            <button @click="editGambar" type="button"
                                                                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                Update
                                                            </button>
                                                        </span>
                                                        <span v-else>
                                                            <button disabled="true" type="button"
                                                                class="text-white bg-gray-400 hover:bg-gray-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-400 dark:hover:bg-gray-400 dark:focus:ring-gray-400">
                                                                Update
                                                            </button>
                                                        </span>
                                                        <button type="button" @click="closeModalImage"
                                                            class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                            Decline
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-2">
                                    <button @click="openModalMobileImage(item.id, item.mobileSlider)"
                                        class="block text-white bg-white-700  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        type=" button">
                                        <svg class="w-6 h-6 text-blue-800 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd"
                                                d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-7Zm.394 9.553a1 1 0 0 0-1.817.062l-2.5 6A1 1 0 0 0 8 19h8a1 1 0 0 0 .894-1.447l-2-4A1 1 0 0 0 13.2 13.4l-.53.706-1.276-2.553ZM13 9.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                    <!-- Main modal -->
                                    <div class="fixed inset-0 bg-black z-50" v-if="isModalMobileImageOpen">
                                        <div aria-hidden="true" class="fixed inset-0 flex items-center justify-center">
                                            <div class="relative p-4 ">
                                                <!-- Modal content -->
                                                <div class="relative bg-white rounded-lg shadow">
                                                    <!-- Modal header -->
                                                    <div
                                                        class="flex items-center justify-between p-4 border-b rounded-t">
                                                        <h3 class="text-xl font-semibold text-gray-900">Mobile
                                                            Slider
                                                            (328 x
                                                            328 Pixels)</h3>
                                                        <button type="button" @click="closeModalMobileImage"
                                                            class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                                            <svg class="w-3 h-3" aria-hidden="true" fill="none"
                                                                viewBox="0 0 14 14">
                                                                <path stroke="currentColor" stroke-linecap="round"
                                                                    stroke-linejoin="round" stroke-width="2"
                                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                            </svg>
                                                            <span class="sr-only">Close modal</span>
                                                        </button>
                                                    </div>
                                                    <!-- Modal body -->
                                                    <div class="p-4 space-y-4">
                                                        <div>
                                                            <div class="max-h-full">
                                                                <label class="cursor-pointer">
                                                                    <div v-if="isLoading"
                                                                        class="flex items-center justify-center w-full h-32">
                                                                        <svg class="animate-spin h-10 w-10 text-gray-500"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none" viewBox="0 0 24 24">
                                                                            <circle class="opacity-25" cx="12" cy="12"
                                                                                r="10" stroke="currentColor"
                                                                                stroke-width="4"></circle>
                                                                            <path class="opacity-75" fill="currentColor"
                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4v4a4 4 0 00-4 4H6v4.291zM20 12a8 8 0 01-8 8v-4a4 4 0 004-4h4zm-2-5.709A8.001 8.001 0 0112 20V16a4 4 0 004-4h4V6.291z">
                                                                            </path>
                                                                        </svg>
                                                                    </div>
                                                                    <img v-else class="w-[328px] h-[328px]  rounded-lg"
                                                                        :src="url_image_mobile" alt="image description">
                                                                    <input accept="image/png, image/jpeg"
                                                                        @change="onFileSelectedMobileImage" type="file"
                                                                        class="hidden" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Modal footer -->
                                                    <div class="flex items-center p-4 border-t rounded-b">
                                                        <span v-if="this.imageUploadMobile !== null">
                                                            <button @click="editGambarMobile" type="button"
                                                                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                Update
                                                            </button>
                                                        </span>
                                                        <span v-else>
                                                            <button disabled="true" type="button"
                                                                class="text-white bg-gray-400 hover:bg-gray-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-400 dark:hover:bg-gray-400 dark:focus:ring-gray-400">
                                                                Update
                                                            </button>
                                                        </span>
                                                        <button type="button" @click="closeModalMobileImage"
                                                            class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                            Decline
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="py-4">
                                    <select v-model="item.orderPosition" @change="logOrderPosition(item)">
                                        <option v-for=" option in options" :key="option" :value="option">{{ option }}
                                        </option>
                                    </select>
                                </td>
                                <td class="flex px-6 py-4">
                                    <span class="mr-2">
                                        <button
                                            @click="openModalEdit(item.id, item.judulSlider, item.startDate, item.endDate, item.linkSlider)"
                                            class="block text-white bg-white-700  font-medium rounded-lg text-sm py-2.5 text-center"
                                            type=" button">
                                            <span>
                                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    fill="currentColor" viewBox="0 0 24 24">
                                                    <path fill-rule="evenodd"
                                                        d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z"
                                                        clip-rule="evenodd" />
                                                    <path fill-rule="evenodd"
                                                        d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                        </button>
                                        <div class="fixed inset-0 bg-black z-50" v-if="isModalEditOpen">
                                            <form @submit.prevent="edit" @keydown.enter.prevent="">
                                                <div aria-hidden="true"
                                                    class="fixed inset-0 flex items-center justify-center">
                                                    <div class="relative p-4 ">
                                                        <!-- Modal content -->
                                                        <div class="relative bg-white rounded-lg shadow">
                                                            <!-- Modal header -->
                                                            <div
                                                                class="flex items-center justify-between p-4 border-b rounded-t">
                                                                <h3 class="text-xl font-semibold text-gray-900">Edit
                                                                    Slider</h3>
                                                                <button type="button" @click="closeModalEdit"
                                                                    class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                                                    <svg class="w-3 h-3" aria-hidden="true" fill="none"
                                                                        viewBox="0 0 14 14">
                                                                        <path stroke="currentColor"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round" stroke-width="2"
                                                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                                    </svg>
                                                                    <span class="sr-only">Close modal</span>
                                                                </button>
                                                            </div>
                                                            <!-- Modal body -->
                                                            <div class="p-4 space-y-4">
                                                                <div class="grid gap-6 mb-6 md:grid-cols-3">
                                                                    <div>
                                                                        <label for="title"
                                                                            class="block mb-2 text-sm font-medium text-gray-900">Title</label>
                                                                        <input v-model="title" type="text" id="title"
                                                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                                                            placeholder="Slider Title" required />
                                                                    </div>
                                                                    <div>
                                                                        <label for="first_name"
                                                                            class="block mb-2 text-sm font-medium text-gray-900">Start
                                                                            Date</label>
                                                                        <div class="relative my-2.5">
                                                                            <VueDatePicker v-model="startDate"
                                                                                :format="dateFormat">
                                                                            </VueDatePicker>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="grid gap-6 mb-6 md:grid-cols-1">
                                                                    <div>
                                                                        <label for="first_name"
                                                                            class="block mb-2 text-sm font-medium text-gray-900">End
                                                                            Date</label>
                                                                        <div class="relative my-2.5">
                                                                            <VueDatePicker v-model="endDate"
                                                                                :format="dateFormat">
                                                                            </VueDatePicker>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <label for="link_url"
                                                                        class="block mb-2 text-sm font-medium text-gray-900">Url
                                                                        Link</label>
                                                                    <input v-model="link_url" type="text" id="title"
                                                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                                                        placeholder="Url Link" required />
                                                                </div>
                                                            </div>
                                                            <!-- Modal footer -->
                                                            <div class="flex items-center p-4 border-t rounded-b">
                                                                <button type="submit"
                                                                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                                    Update
                                                                </button>
                                                                <button type="button" @click="closeModalEdit"
                                                                    class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                                    Decline
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </span>
                                    <span>
                                        <button @click="deleteData(item.id)"
                                            class="block text-white bg-white-700  font-medium rounded-lg text-sm py-2.5 text-center"
                                            type=" button">
                                            <span>
                                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    fill="currentColor" viewBox="0 0 24 24">
                                                    <path fill-rule="evenodd"
                                                        d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                        </button>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <nav class="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
                    aria-label="Table navigation">
                    <span
                        class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">Showing
                        <span class="font-semibold text-gray-900 dark:text-white">
                            {{ (pageInfo.pageNumber - 1) * pageInfo.pageSize + 1 }} -
                            {{ Math.min(pageInfo.pageNumber * pageInfo.pageSize, pageInfo.totalElements) }}
                        </span> of <span class="font-semibold text-gray-900 dark:text-white">{{
                            pageInfo.totalElements
                        }}</span></span>
                    <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                        <li v-if="this.pageInfo.pageNumber > 1"> <a href="#" @click="goToPreviousPage"
                                class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                Previous</a>
                        </li>
                        <li v-for="pageNumber in visiblePages" :key="pageNumber">
                            <a href="#" aria-current="page" @click="goToPage(pageNumber)"
                                :class="{ 'bg-blue-700 text-white': pageNumber === pageInfo.pageNumber }"
                                class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{
                                    pageNumber }}</a>
                        </li>
                        <li>
                            <a href="#" @click="goToNextPage"
                                class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { format } from 'date-fns'
import { id } from 'date-fns/locale'
import Swal from 'sweetalert2'
import image1 from '@/assets/image-1@2x.jpg'

export default {
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            url_image: require("@/assets/image-1@2x.jpg"),
            url_image_mobile: require("@/assets/image-1@2x.jpg"),
            isModalImageOpen: false,
            isModalMobileImageOpen: false,
            isModalCreateOpen: false,
            isModalEditOpen: false,
            isLoading: false,
            isLoadingScreen: false,
            clickedItemId: null,
            imageUpload: null,
            imageUploadMobile: null,
            title: "",
            link_url: "",
            startDate: ref(new Date()),
            endDate: ref(new Date()),
            dateFormat: 'yyyy-MM-dd',
            agensi: null,
            idSlider: null,
            orderPosition: null,
            breadCrumb: {
                root: { text: "Home", url: "" },
                child: [
                    { text: "Slider", url: "" },
                    { text: "List", url: "" }
                ]
            },
            pageInfo: {
                totalPages: 0,
                pageNumber: 0,
                visiblePages: 5,
                pageSize: 5,
                totalElements: 0,
                isSearch: false,
            },
        }
    },
    created() {
        var parameters = this.$route.params
        this.fetchData(parameters.agensi, parseInt(parameters.pageNumber), parameters.isSearch)
    },
    computed: {
        options() {
            return Array.from({ length: this.pageInfo.totalElements }, (_, i) => i + 1);
        },
        getListSlider() {
            return this.$store.getters.getListSlider
        },
        visiblePages() {
            const activePage = this.pageInfo.pageNumber;
            const totalVisiblePages = this.pageInfo.visiblePages;
            const totalPages = this.pageInfo.totalPages;

            let startPage = activePage - Math.floor(totalVisiblePages / 2)
            startPage = Math.max(startPage, 1)
            let endPage = startPage + totalVisiblePages - 1
            endPage = Math.min(endPage, totalPages)

            if (endPage === totalPages && totalPages - totalVisiblePages + 1 > 0) {
                startPage = totalPages - totalVisiblePages + 1
            }

            let visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)

            if (endPage < totalPages) {
                visiblePages.push(endPage + 1)
            }

            return visiblePages;
        }
    },
    watch: {
        items: {
            handler(items) {
                // Memastikan item ada sebelum melakukan operasi apa pun
                if (items && Array.isArray(items)) {
                    items.forEach(item => {
                        this.$watch(
                            () => item.orderPosition,
                            (newValue) => {
                                item.orderPosition = newValue;
                            },
                            { immediate: true }
                        );
                    });
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        formatDate(date) {
            return format(new Date(date), 'EEEE, dd MMMM yyyy', { locale: id });
        },
        async fetchData(agensi, pageNumber, isSearch) {
            this.pageInfo.pageNumber = pageNumber
            this.pageInfo.isSearch = isSearch
            this.agensi = agensi
            await this.$store.dispatch('listSlider', {
                index: (this.pageInfo.pageNumber - 1),
                limit: this.pageInfo.pageSize,
            }).then(() => {
                this.isLoadingScreen = true
                this.pageInfo.totalElements = this.getListSlider.totalElements
                this.pageInfo.totalPages = this.getListSlider.totalPages
            }).finally(() => {
                this.isLoadingScreen = false
            })
        },
        async goToPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.pageInfo.totalPages) {
                await this.fetchData(this.agensi, pageNumber, this.pageInfo.isSearch)
                this.tempRouteList(this.agensi, this.pageInfo.isSearch, pageNumber)
            }
        },
        async goToNextPage() {
            const nextPageNumber = this.pageInfo.pageNumber + 1;
            if (nextPageNumber <= this.pageInfo.totalPages) {
                await this.fetchData(this.agensi, nextPageNumber, this.pageInfo.isSearch)
                this.tempRouteList(this.agensi, this.pageInfo.isSearch, nextPageNumber)
            }
        },
        async goToPreviousPage() {
            const previousPageNumber = this.pageInfo.pageNumber - 1
            if (previousPageNumber >= 1) {
                await this.fetchData(this.agensi, previousPageNumber, this.pageInfo.isSearch);
                this.tempRouteList(this.agensi, this.pageInfo.isSearch, previousPageNumber)
            }
        },
        tempRouteList(agensi, isSearch, pageNumber) {
            this.$router.push({ name: 'list-slider', params: { agensi: agensi, isSearch: isSearch, pageNumber: pageNumber } })
        },
        openModalImage(itemId, itemGambar) {
            this.clickedItemId = itemId
            this.isLoading = true
            this.url_image = itemGambar || null
            this.isModalImageOpen = true

            const imgElement = new Image()
            imgElement.src = itemGambar

            imgElement.addEventListener('load', () => {
                this.isLoading = false;
            })

            imgElement.addEventListener('error', () => {
                this.isLoading = false;
                console.error('Error loading image')
            })
        },
        closeModalImage() {
            this.isModalImageOpen = false
            this.imageUpload = null
            this.url_image = image1
        },
        onFileSelectedImage(event) {
            const inputElement = event.target
            const file = inputElement.files?.[0]
            this.url_image = file ? URL.createObjectURL(file) : ""
            this.imageUpload = file ? file : ""
        },
        editGambar() {
            if (this.imageUpload.size > (500 * 1024)) {
                Swal.fire({
                    title: "Warning",
                    text: "File size should not exceed 500 KB",
                    icon: "warning"
                })
            } else {
                const image = new Image();
                image.src = URL.createObjectURL(this.imageUpload)
                image.onload = () => {
                    const width = image.naturalWidth
                    const height = image.naturalHeight

                    if (width !== 1224 || height !== 400) {
                        Swal.fire({
                            title: "Warning",
                            text: "Image dimensions should be 1224 x 400 pixels",
                            icon: "warning"
                        });
                    } else {
                        Swal
                            .fire({
                                title: "Are you sure?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, Edit",
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    this.isLoading = true
                                    this.$store.dispatch('editDefaultSlider', {
                                        id: this.clickedItemId,
                                        defaultSlider: this.imageUpload
                                    }).then(() => {
                                        Swal.fire({
                                            title: "Edit Success",
                                            text: "Sukses Edit Default Slider!",
                                            icon: "success"
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                this.isLoading = false
                                                this.isModalImageOpen = false
                                                location.reload()
                                            }
                                        })
                                    }).catch((error) => {
                                        Swal.fire({
                                            title: "Error",
                                            text: "Gagal Edit Default Slider: " + error,
                                            icon: "error"
                                        })
                                    })
                                }
                            })
                    }
                }
            }
        },
        editGambarMobile() {
            if (this.imageUploadMobile.size > (500 * 1024)) {
                Swal.fire({
                    title: "Warning",
                    text: "File size should not exceed 500 KB",
                    icon: "warning"
                })
            } else {
                const image = new Image();
                image.src = URL.createObjectURL(this.imageUploadMobile)
                image.onload = () => {
                    const width = image.naturalWidth
                    const height = image.naturalHeight

                    if (width !== 328 || height !== 328) {
                        Swal.fire({
                            title: "Warning",
                            text: "Image dimensions should be 328 x 328 pixels",
                            icon: "warning"
                        });
                    } else {
                        Swal
                            .fire({
                                title: "Are you sure?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, Edit",
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    this.isLoading = true
                                    this.$store.dispatch('editMobileSlider', {
                                        id: this.clickedItemId,
                                        mobileSlider: this.imageUploadMobile
                                    }).then(() => {
                                        Swal.fire({
                                            title: "Edit Success",
                                            text: "Sukses Edit Mobile Slider!",
                                            icon: "success"
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                this.isLoading = false
                                                this.isModalImageOpen = false
                                                location.reload()
                                            }
                                        })
                                    }).catch((error) => {
                                        Swal.fire({
                                            title: "Error",
                                            text: "Gagal Edit Mobile Slider: " + error,
                                            icon: "error"
                                        })
                                    })
                                }
                            })
                    }
                }
            }
        },
        openModalMobileImage(itemId, itemGambar) {
            console.log(itemGambar)
            this.clickedItemId = itemId
            this.isLoading = true
            if (itemGambar !== null) {
                this.url_image_mobile = itemGambar
            }
            this.isModalMobileImageOpen = true

            const imgElement = new Image()
            imgElement.src = itemGambar

            imgElement.addEventListener('load', () => {
                this.isLoading = false;
            })

            imgElement.addEventListener('error', () => {
                this.isLoading = false;
                console.error('Error loading image')
            })
        },
        closeModalMobileImage() {
            this.isModalMobileImageOpen = false
            this.imageUploadMobile = null
            this.url_image_mobile = image1
        },
        onFileSelectedMobileImage(event) {
            const inputElement = event.target
            const file = inputElement.files?.[0]
            this.url_image_mobile = file ? URL.createObjectURL(file) : ""
            this.imageUploadMobile = file ? file : ""
        },
        openModalCreate() {
            this.isModalCreateOpen = true
        },
        closeModalCreate() {
            this.isModalCreateOpen = false
            this.imageUpload = null
            this.imageUploadMobile = null
        },
        onFileSelectedImageCreate(event) {
            const inputElement = event.target
            const file = inputElement.files?.[0]
            this.url_image = file ? URL.createObjectURL(file) : ""
            this.imageUpload = file ? file : ""
        },
        onFileSelectedImageMobileCreate(event) {
            const inputElement = event.target
            const file = inputElement.files?.[0]
            this.url_image_mobile = file ? URL.createObjectURL(file) : ""
            this.imageUploadMobile = file ? file : ""
        },
        openModalEdit(id, judulSlider, startDate, endDate, linkSlider) {
            this.isModalEditOpen = true
            this.idSlider = id
            this.title = judulSlider
            this.startDate = startDate
            this.endDate = endDate
            this.link_url = linkSlider
        },
        closeModalEdit() {
            this.isModalEditOpen = false
            this.idSlider = null
            this.title = ""
            this.startDate = ref(new Date())
            this.endDate = ref(new Date())
            this.link_url = ""
        },
        create() {
            if (format(new Date(this.endDate), 'yyyy-MM-dd') <= format(new Date(this.startDate), 'yyyy-MM-dd')) {
                Swal.fire({
                    title: "Warning",
                    text: "End Date Cannot be Less Than or Equal to Start Date",
                    icon: "warning"
                });
                return
            }

            if (this.imageUpload == null || this.imageUploadMobile == null) {
                Swal.fire({
                    title: "Warning",
                    text: "Image Slider Cannot Be Empty",
                    icon: "warning"
                });
                return
            }

            if (this.imageUpload.size > (500 * 1024)) {
                Swal.fire({
                    title: "Warning",
                    text: "Default Slider size should not exceed 500 KB",
                    icon: "warning"
                });
                return
            }

            if (this.imageUploadMobile.size > (500 * 1024)) {
                Swal.fire({
                    title: "Warning",
                    text: "Mobile Slider size should not exceed 500 KB",
                    icon: "warning"
                });
                return
            }

            const image = new Image();
            image.src = URL.createObjectURL(this.imageUpload);
            image.onload = () => {
                const width = image.naturalWidth;
                const height = image.naturalHeight;

                if (width !== 1224 || height !== 400) {
                    Swal.fire({
                        title: "Warning",
                        text: "Default Slider dimensions should be 1224 x 400 pixels",
                        icon: "warning"
                    });
                    return
                }

                const image2 = new Image();
                image2.src = URL.createObjectURL(this.imageUploadMobile);
                image2.onload = () => {
                    const width2 = image2.naturalWidth;
                    const height2 = image2.naturalHeight;

                    if (width2 !== 328 || height2 !== 328) {
                        Swal.fire({
                            title: "Warning",
                            text: "Mobile Slider dimensions should be 328 x 328 pixels",
                            icon: "warning"
                        });
                        return;
                    }

                    Swal
                        .fire({
                            title: "Are you sure?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, Create",
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                this.isLoadingScreen = true;
                                this.$store.dispatch('createSlider', {
                                    judulSlider: this.title,
                                    startDate: format(new Date(this.startDate), 'yyyy-MM-dd'),
                                    endDate: format(new Date(this.endDate), 'yyyy-MM-dd'),
                                    url: this.link_url,
                                    defaultSlider: this.imageUpload,
                                    mobileSlider: this.imageUploadMobile
                                }).then(() => {
                                    Swal.fire({
                                        title: "Create Success",
                                        text: "Sukses Membuat Slider!",
                                        icon: "success"
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.isLoadingScreen = false;
                                            location.reload();
                                        }
                                    });
                                }).catch((error) => {
                                    Swal.fire({
                                        title: "Error",
                                        text: "Gagal membuat Slider: " + error,
                                        icon: "error"
                                    })
                                })
                            }
                        })
                }
            }
        },
        edit() {
            if (format(new Date(this.endDate), 'yyyy-MM-dd') <= format(new Date(this.startDate), 'yyyy-MM-dd')) {
                Swal.fire({
                    title: "Warning",
                    text: "End Date Cannot be Less Than or Equal to Start Date",
                    icon: "warning"
                });
                return
            }

            Swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Edit",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoadingScreen = true;
                        this.$store.dispatch('editSlider', {
                            id: this.idSlider,
                            judulSlider: this.title,
                            startDate: format(new Date(this.startDate), 'yyyy-MM-dd'),
                            endDate: format(new Date(this.endDate), 'yyyy-MM-dd'),
                            url: this.link_url
                        }).then(() => {
                            Swal.fire({
                                title: "Edit Success",
                                text: "Sukses Mengubah Slider!",
                                icon: "success"
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.isLoadingScreen = false;
                                    location.reload();
                                }
                            });
                        }).catch((error) => {
                            Swal.fire({
                                title: "Error",
                                text: "Gagal Mengubah Slider: " + error,
                                icon: "error"
                            })
                            this.isLoadingScreen = false;
                        })
                    }
                })
        },
        deleteData(id) {
            Swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Delete",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoadingScreen = true
                        this.$store.dispatch('deleteSlider', {
                            id: id,
                        }).then(() => {
                            Swal.fire({
                                title: "Delete Success",
                                text: "Sukses Hapus Slider!",
                                icon: "success"
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.isLoadingScreen = false
                                    location.reload()
                                }
                            })
                        }).catch((error) => {
                            Swal.fire({
                                title: "Error",
                                text: "Gagal Hapus Slider: " + error,
                                icon: "error"
                            })
                        })
                    }
                })
        },
        logOrderPosition(item) {
            this.$store.dispatch('moveSlider', {
                idSlider: item.id,
                newPosition: item.orderPosition,
            }).then(() => {
                location.reload()
            })
        }
    }
}
</script>