<template>
    <div v-if="isLoadingScreen" class="fixed inset-0 flex items-center justify-center bg-gray-500 z-50">
        <div role="status">
            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="p-4 sm:ml-64">
        <div class="p-4">
            <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                            <li class="inline-flex items-center">
                                <a href="#"
                                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    {{ breadCrumb.root.text }}
                                </a>
                            </li>
                            <li v-for="items in breadCrumb.child" :key="items.text">
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <a href="#"
                                        class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">{{
                                            items.text
                                        }}</a>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="relative sm:rounded-lg mb-3">
                    <div class="justify-start">
                        <form @submit.prevent="searchEmail">
                            <div class="flex">
                                <div class="relative w-full">
                                    <input v-model="email" type="email" id="search-dropdown"
                                        class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-s-lg rounded-e-lg  border-s-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                                        placeholder="Email..." required />
                                    <button type="submit"
                                        class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-red-700 rounded-e-lg border border-red-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-red-600 dark:hover:bg-gray-700 dark:focus:ring-red-800">
                                        <svg class="w-6 h-6 text-white-800 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd"
                                                d="M9 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4H7Zm8-1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z"
                                                clip-rule="evenodd" />
                                        </svg>
                                        <span class="sr-only">Email</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="grid gap-6 mb-2 md:grid-cols-2">
                    <div class="justify-start">
                        <form @submit.prevent="searchCommunity">
                            <div class="flex">

                                <div class="relative w-full">
                                    <input v-model="search" type="search" id="search-dropdown"
                                        class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-s-lg rounded-e-lg  border-s-1 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                                        placeholder="Search..." required />
                                    <button type="submit"
                                        class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                            fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                        <span class="sr-only">Search</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <button @click="resetCommunity"
                        class="max-w-10 bg-blue-700 text-white px-2 py-1 rounded-md shadow-md hover:bg-gray-700 transition-colors duration-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                    </button>
                </div>

                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Email
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Role
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white  dark:bg-gray-800 " v-for="item in picCommunity.content" :key="item.id">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 text-warp dark:text-white">
                                    {{ item.username }}
                                </td>
                                <td class="px-1 py-4">
                                    {{ item.email }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ item.role }}
                                </td>
                                <td class="px-6 py-4">
                                    <span v-if="!item.pic" class="flex">
                                        <span>
                                            <button @click="roleACL(item)"
                                                class="block text-white bg-white-700  font-medium rounded-lg text-sm py-2.5 text-center"
                                                type=" button">
                                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                                        d="M20 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6h-2m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4m16 6H10m0 0a2 2 0 1 0-4 0m4 0a2 2 0 1 1-4 0m0 0H4" />
                                                </svg>
                                            </button>
                                            <div class="fixed inset-0 bg-black z-50" v-if="isModalACLOpen">
                                                <div aria-hidden="true"
                                                    class="fixed inset-0 flex items-center justify-center">
                                                    <div class="relative p-4 ">
                                                        <!-- Modal content -->
                                                        <div class="relative bg-white rounded-lg shadow">
                                                            <!-- Modal header -->
                                                            <div
                                                                class="flex items-center justify-between p-4 border-b rounded-t">
                                                                <h3 class="text-xl font-semibold text-gray-900">ACL
                                                                    Controls</h3>
                                                                <button type="button" @click="closeModalACL"
                                                                    class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                                                    <svg class="w-3 h-3" aria-hidden="true" fill="none"
                                                                        viewBox="0 0 14 14">
                                                                        <path stroke="currentColor"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round" stroke-width="2"
                                                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                                    </svg>
                                                                    <span class="sr-only">Close modal</span>
                                                                </button>
                                                            </div>
                                                            <!-- Modal body -->
                                                            <div class="p-4 space-y-4">
                                                                <div>
                                                                    <div class="max-h-full">
                                                                        <label class="cursor-pointer">
                                                                            <div
                                                                                class="max-h-96 max-w-400 overflow-y-auto">
                                                                                <table
                                                                                    class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                                                    <thead
                                                                                        class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                                                        <tr>
                                                                                            <th scope="col"
                                                                                                class="px-6 py-3">
                                                                                                Menu ID
                                                                                            </th>
                                                                                            <th scope="col"
                                                                                                class="px-6 py-3">
                                                                                                Email
                                                                                            </th>
                                                                                            <th scope="col"
                                                                                                class="px-6 py-3">
                                                                                                Menu
                                                                                            </th>
                                                                                            <th scope="col"
                                                                                                class="px-6 py-3">
                                                                                                Action
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr class="bg-white  dark:bg-gray-800 "
                                                                                            v-for="items in getListACL"
                                                                                            :key="items.menuId">
                                                                                            <td scope="row"
                                                                                                class="px-6 py-4 font-medium text-gray-900 text-warp dark:text-white">
                                                                                                {{ items.menuId }}
                                                                                            </td>
                                                                                            <td scope="row"
                                                                                                class="px-6 py-4 font-medium text-gray-900 text-warp dark:text-white">
                                                                                                {{ items.email }}
                                                                                            </td>
                                                                                            <td scope="row"
                                                                                                class="px-6 py-4 font-medium text-gray-900 text-warp dark:text-white">
                                                                                                {{ items.acl }}
                                                                                            </td>
                                                                                            <td scope="row"
                                                                                                class="px-6 py-4 font-medium text-gray-900 text-warp dark:text-white">
                                                                                                <div
                                                                                                    class="tems-center">
                                                                                                    <input
                                                                                                        @click="statusACL(items)"
                                                                                                        :checked="items.status"
                                                                                                        id="checked-checkbox"
                                                                                                        type="checkbox"
                                                                                                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                        <span>
                                            <button @click="deletePICCommunity(item)"
                                                class="block text-white bg-white-700  font-medium rounded-lg text-sm py-2.5 text-center"
                                                type=" button">
                                                <span>
                                                    <svg class="w-6 h-6 text-gray-800 dark:text-white"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                        height="24" fill="currentColor" viewBox="0 0 24 24">
                                                        <path fill-rule="evenodd"
                                                            d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                                            clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <nav class="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
                    aria-label="Table navigation">
                    <span
                        class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">Showing
                        <span class="font-semibold text-gray-900 dark:text-white">
                            {{ (pageInfo.pageNumber - 1) * pageInfo.pageSize + 1 }} -
                            {{ Math.min(pageInfo.pageNumber * pageInfo.pageSize, pageInfo.totalElements) }}
                        </span> of <span class="font-semibold text-gray-900 dark:text-white">{{
                            pageInfo.totalElements
                        }}</span></span>
                    <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                        <li v-if="this.pageInfo.pageNumber > 1"><a href="#" @click="goToPreviousPage"
                                class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                Previous</a>
                        </li>
                        <li v-for="pageNumber in visiblePages" :key="pageNumber">
                            <a href="#" aria-current="page" @click="goToPage(pageNumber)"
                                :class="{ 'bg-blue-700 text-white': pageNumber === pageInfo.pageNumber }"
                                class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{
                                    pageNumber }}</a>
                        </li>
                        <li>
                            <a href="#" @click="goToNextPage"
                                class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            isLoading: false,
            isLoadingScreen: false,
            isModalACLOpen: false,
            email: "",
            search: "",
            agensi: null,
            idCommunity: null,
            breadCrumb: {
                root: { text: "Home", url: "" },
                child: [
                    { text: "Agensi", url: "" },
                    { text: "List", url: "" },
                    { text: "PIC", url: "" },
                ]
            },
            pageInfo: {
                totalPages: 0,
                pageNumber: 0,
                visiblePages: 5,
                pageSize: 5,
                totalElements: 0,
                isSearch: false,
            }
        }
    },
    created() {
        var parameters = this.$route.params
        this.fetchPICCommunityData(parameters.agensi, parameters.idCommunity, parseInt(parameters.pageNumber), parameters.isSearch, parameters.search)
    },
    computed: {
        picCommunity() {
            return this.$store.getters.getPICAgensi2
        },
        addPICCommunity() {
            return this.$store.getters.getAddPICAgensi2
        },
        visiblePages() {
            const activePage = this.pageInfo.pageNumber;
            const totalVisiblePages = this.pageInfo.visiblePages;
            const totalPages = this.pageInfo.totalPages;

            let startPage = activePage - Math.floor(totalVisiblePages / 2)
            startPage = Math.max(startPage, 1)
            let endPage = startPage + totalVisiblePages - 1
            endPage = Math.min(endPage, totalPages)

            if (endPage === totalPages && totalPages - totalVisiblePages + 1 > 0) {
                startPage = totalPages - totalVisiblePages + 1
            }

            let visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)

            if (endPage < totalPages) {
                visiblePages.push(endPage + 1)
            }

            return visiblePages;
        },
        getListACL() {
            return this.$store.getters.getListACL
        }
    },
    methods: {
        async fetchPICCommunityData(agensi, idCommunity, pageNumber, isSearch, search) {
            this.pageInfo.pageNumber = pageNumber
            this.pageInfo.isSearch = isSearch
            this.agensi = agensi
            this.idCommunity = idCommunity

            await this.$store.dispatch('listPICAgensi2', {
                isSearch: this.pageInfo.isSearch,
                search: search,
                idAgensi: this.idCommunity,
                idRoleLink: 1,
                idRoleLink2: 4,
                index: (this.pageInfo.pageNumber - 1),
                limit: this.pageInfo.pageSize,
            }).then(() => {
                this.isLoadingScreen = true
                this.pageInfo.totalElements = this.picCommunity.totalElements
                this.pageInfo.totalPages = this.picCommunity.totalPages
            }).finally(() => {
                this.isLoadingScreen = false
            })
        },
        async fetchDataACL(email) {
            await this.$store.dispatch('listACL', {
                email: email
            }).then(() => {
                this.isLoadingScreen = true
                this.isModalACLOpen = true
            }).finally(() => {
                this.isLoadingScreen = false
            })
        },
        async resetCommunity() {
            this.search = ""
            await this.fetchPICCommunityData(this.agensi, this.idCommunity, 1, false, this.search)
            this.tempRouteList(this.agensi, this.idCommunity, this.pageInfo.isSearch, this.pageInfo.pageNumber)
        },
        async searchCommunity() {
            await this.fetchPICCommunityData(this.agensi, this.idCommunity, 1, true, this.search)
            this.tempRouteListSearch(this.agensi, this.idCommunity, this.pageInfo.isSearch, this.pageInfo.pageNumber, this.search)
        },
        async goToPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.pageInfo.totalPages) {
                await this.fetchPICCommunityData(this.agensi, this.idCommunity, pageNumber, false, this.search)
                if (this.pageInfo.isSearch === true) {
                    this.tempRouteListSearch(this.agensi, this.idCommunity, this.pageInfo.isSearch, this.pageInfo.pageNumber, this.search)
                } else {
                    this.tempRouteList(this.agensi, this.idCommunity, this.pageInfo.isSearch, pageNumber)
                }
            }
        },
        async goToNextPage() {
            const nextPageNumber = this.pageInfo.pageNumber + 1;
            if (nextPageNumber <= this.pageInfo.totalPages) {
                await this.fetchPICCommunityData(this.agensi, this.idCommunity, nextPageNumber, this.pageInfo.isSearch, this.search)
                if (this.pageInfo.isSearch === true) {
                    this.tempRouteListSearch(this.agensi, this.idCommunity, this.pageInfo.isSearch, nextPageNumber, this.search)
                } else {
                    this.tempRouteList(this.agensi, this.idCommunity, this.pageInfo.isSearch, nextPageNumber)
                }
            }
        },
        async goToPreviousPage() {
            const previousPageNumber = this.pageInfo.pageNumber - 1
            if (previousPageNumber >= 1) {
                await this.fetchPICCommunityData(this.agensi, this.idCommunity, previousPageNumber, this.pageInfo.isSearch, this.search)
                if (this.pageInfo.isSearch === true) {
                    this.tempRouteListSearch(this.agensi, this.idCommunity, this.pageInfo.isSearch, previousPageNumber)
                } else {
                    this.tempRouteList(this.agensi, this.idCommunity, this.pageInfo.isSearch, previousPageNumber)
                }
            }
        },
        tempRouteListSearch(agensi, idCommunity, isSearch, pageNumber, search) {
            this.$router.push({ name: 'list-pic-agensi-search', params: { agensi: agensi, idCommunity: idCommunity, isSearch: isSearch, pageNumber: pageNumber, search: search.replace(/\s+/g, '-') } })
        },
        tempRouteList(agensi, idCommunity, isSearch, pageNumber) {
            this.$router.push({ name: 'list-pic-agensi', params: { agensi: agensi, idCommunity: idCommunity, isSearch: isSearch, pageNumber: pageNumber } })
        },
        searchEmail() {
            Swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Add",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoadingScreen = true
                        this.$store.dispatch('addPICAgensi2', {
                            idAgensi: this.$route.params.idCommunity,
                            email: this.email
                        }).then(() => {
                            this.isLoadingScreen = true
                            if (this.addPICCommunity.status === 200) {
                                Swal.fire({
                                    title: "Create Success",
                                    text: this.addPICCommunity.message,
                                    icon: "success"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        location.reload()
                                    }
                                })
                            } else {
                                Swal.fire({
                                    title: "Warning",
                                    text: this.addPICCommunity.message,
                                    icon: "warning"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        location.reload()
                                    }
                                })
                            }
                        })
                    }
                })
        },
        roleACL(item) {
            this.fetchDataACL(item.email)
        },
        closeModalACL() {
            this.isModalACLOpen = false
        },
        async statusACL(item) {
            item.status = !item.status;
            await this.$store.dispatch('changeStatusACL', {
                email: item.email,
                menuId: item.menuId,
                status: item.status
            })
        },
        deletePICCommunity(item) {
            Swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Delete",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoadingScreen = true
                        this.$store.dispatch('deletePICAgensi2', {
                            idUser: item.id,
                            idAgensi: this.$route.params.idCommunity,
                            email: item.email
                        }).then(() => {
                            Swal.fire({
                                title: "Delete Success",
                                text: "Sukses Menghapus PIC!",
                                icon: "success"
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.isLoadingScreen = false
                                    location.reload()
                                }
                            })
                        }).catch((error) => {
                            Swal.fire({
                                title: "Error",
                                text: "Gagal Menghapus PIC: " + error,
                                icon: "error"
                            })
                        })
                    }
                })
        },
    }
}
</script>