import { createApp } from 'vue'
import { initializeApp } from 'firebase/app'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './index.css'
import 'flowbite'
import vue3GoogleLogin from 'vue3-google-login'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import TinyMCE from '@/components/TinyMCE.vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import BibleHint from './components/BibleHint.vue'


const app = createApp(App)

app.component('BibleHint', BibleHint)

const firebaseConfig = {
    apiKey: 'AIzaSyBOJ3P2cz8R5xhGSC5yKYNCtzQD7W378P8',
    authDomain: 'smart-bible-lai.firebaseapp.com',
    projectId: 'smart-bible-lai',
    storageBucket: 'smart-bible-lai.appspot.com',
    messagingSenderId: "529818590065",
    appId: '1:529818590065:web:bd5c437cc2c253d32af4d0',
    measurementId: 'G-3RVP4609M3'
}

initializeApp(firebaseConfig)

app.use(vue3GoogleLogin, {
    clientId: '529818590065-ck0d2d5m8gbp88jodn0sefm0lhlnivmr.apps.googleusercontent.com'
})

app
    .use(store)
    .use(router)
    .component('VueDatePicker', VueDatePicker)
    .component('TinyMCE', TinyMCE)
    .use(VueSweetalert2)
    .mount('#app')
