<template>
    <div>
        <button type="button" @click="openPopup">
            <svg class="w-[20px] h-[20px] text-yellow-300 dark:text-white" aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd"
                    d="M7.05 4.05A7 7 0 0 1 19 9c0 2.407-1.197 3.874-2.186 5.084l-.04.048C15.77 15.362 15 16.34 15 18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1c0-1.612-.77-2.613-1.78-3.875l-.045-.056C6.193 12.842 5 11.352 5 9a7 7 0 0 1 2.05-4.95ZM9 21a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Zm1.586-13.414A2 2 0 0 1 12 7a1 1 0 1 0 0-2 4 4 0 0 0-4 4 1 1 0 0 0 2 0 2 2 0 0 1 .586-1.414Z"
                    clip-rule="evenodd" />
            </svg>
        </button>
        <div v-if="popupHint" tabindex="-1" aria-hidden="true"
            class="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
            <div class="relative w-full max-w-2xl p-4 max-h-full">
                <!-- Modal content -->
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <!-- Modal header -->
                    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                            Hint
                        </h3>
                        <button type="button" @click="closePopUp"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="default-modal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="md:p-5 space-y-4 overflow-auto max-h-[70vh]">
                        <form class="flex max-w-sm">
                            <div class="w-full">
                                <input type="text" id="simple-search" v-model="searchTerm"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Search book" required />
                            </div>
                            <button type="button"
                                class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                                <span class="sr-only">Search</span>
                            </button>
                        </form>
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead
                                class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" class="px-6 py-3">
                                        Book
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Code
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                    v-for="item in filteredBible" :key="item.book">
                                    <td class="px-6 py-4">
                                        {{ item.book }}
                                    </td>
                                    <td class="px-6 py-4">
                                        {{ item.code }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "BibleHint",
    data() {
        return {
            popupHint: false,
            searchTerm: "",
            bible: [
                {
                    book: "KEJADIAN",
                    code: "GEN"
                },
                {
                    book: "KELUARAN",
                    code: "EXO"
                },
                {
                    book: "IMAMAT",
                    code: "LEV"
                },
                {
                    book: "BILANGAN",
                    code: "NUM"
                },
                {
                    book: "ULANGAN",
                    code: "DEU"
                },
                {
                    book: "YOSUA",
                    code: "JOS"
                },
                {
                    book: "HAKIM-HAKIM",
                    code: "JDG"
                },
                {
                    book: "RUTH",
                    code: "RUT"
                },
                {
                    book: "1 SAMUEL",
                    code: "1SA"
                },
                {
                    book: "2 SAMUEL",
                    code: "2SA"
                },
                {
                    book: "1 RAJA-RAJA",
                    code: "1KI"
                },
                {
                    book: "2 RAJA-RAJA",
                    code: "2KI"
                },
                {
                    book: "1 TAWARIKH",
                    code: "1CH"
                },
                {
                    book: "2 TAWARIKH",
                    code: "2CH"
                },
                {
                    book: "EZRA",
                    code: "EZR"
                },
                {
                    book: "NEHEMIA",
                    code: "NEH"
                },
                {
                    book: "ESTER",
                    code: "EST"
                },
                {
                    book: "AYUB",
                    code: "JOB"
                },
                {
                    book: "MAZMUR",
                    code: "PSA"
                },
                {
                    book: "AMSAL",
                    code: "PRO"
                },
                {
                    book: "PENGKHOTBAH",
                    code: "ECC"
                },
                {
                    book: "KIDUNG AGUNG",
                    code: "SNG"
                },
                {
                    book: "YESAYA",
                    code: "ISA"
                },
                {
                    book: "YEREMIA",
                    code: "JER"
                },
                {
                    book: "RATAPAN",
                    code: "LAM"
                },
                {
                    book: "YEHEZKIEL",
                    code: "EZK"
                },
                {
                    book: "DANIEL",
                    code: "DAN"
                },
                {
                    book: "HOSEA",
                    code: "HOS"
                },
                {
                    book: "YOEL",
                    code: "JOL"
                },
                {
                    book: "AMOS",
                    code: "AMO"
                },
                {
                    book: "OBAJA",
                    code: "OBA"
                },
                {
                    book: "YUNUS",
                    code: "JON"
                },
                {
                    book: "MIKHA",
                    code: "MIC"
                },
                {
                    book: "NAHUM",
                    code: "NAM"
                },
                {
                    book: "HABAKUK",
                    code: "HAB"
                },
                {
                    book: "ZEFANYA",
                    code: "ZEP"
                },
                {
                    book: "HAGAI",
                    code: "HAG"
                },
                {
                    book: "ZAKHARIA",
                    code: "ZEC"
                },
                {
                    book: "MALEAKHI",
                    code: "MAL"
                },
                {
                    book: "MATIUS",
                    code: "MAT"
                },
                {
                    book: "MARKUS",
                    code: "MRK"
                },
                {
                    book: "LUKAS",
                    code: "LUK"
                },
                {
                    book: "YOHANES",
                    code: "JHN"
                },
                {
                    book: "KISAH PARA RASUL",
                    code: "ACT"
                },
                {
                    book: "ROMA",
                    code: "ROM"
                },
                {
                    book: "1 KORINTUS",
                    code: "1CO"
                },
                {
                    book: "2 KORINTUS",
                    code: "2CO"
                },
                {
                    book: "GALATIA",
                    code: "GAL"
                },
                {
                    book: "EFESUS",
                    code: "EPH"
                },
                {
                    book: "FILIPI",
                    code: "PHP"
                },
                {
                    book: "KOLOSE",
                    code: "COL"
                },
                {
                    book: "1 TESALONIKA",
                    code: "1TH"
                },
                {
                    book: "2 TESALONIKA",
                    code: "2TH"
                },
                {
                    book: "1 TIMOTIUS",
                    code: "1TI"
                },
                {
                    book: "2 TIMOTIUS",
                    code: "2TI"
                },
                {
                    book: "TITUS",
                    code: "TIT"
                },
                {
                    book: "FILEMON",
                    code: "PHM"
                },
                {
                    book: "IBRANI",
                    code: "HEB"
                },
                {
                    book: "YAKOBUS",
                    code: "JAS"
                },
                {
                    book: "1 PETRUS",
                    code: "1PE"
                },
                {
                    book: "2 PETRUS",
                    code: "2PE"
                },
                {
                    book: "1 YOHANES",
                    code: "1JN"
                },
                {
                    book: "2 YOHANES",
                    code: "2JN"
                },
                {
                    book: "3 YOHANES",
                    code: "3JN"
                },
                {
                    book: "YUDAS",
                    code: "JUD"
                },
                {
                    book: "WAHYU",
                    code: "REV"
                },
            ],
        }
    },
    computed: {
        filteredBible() {
            return this.bible.filter(item =>
                item.book.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        }
    },
    methods: {
        openPopup() {
            this.popupHint = true
        },
        closePopUp() {
            this.popupHint = false
        }
    }
}
</script>