<template>
    <div class="p-4 sm:ml-64">
        <div class="p-4">
            <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                            <li class="inline-flex items-center">
                                <a href="#"
                                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    {{ breadCrumb.root.text }}
                                </a>
                            </li>
                            <li v-for="items in breadCrumb.child" :key="items.text">
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <a href="#"
                                        class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">{{
                                            items.text
                                        }}</a>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="grid gap-6 mb-6 md:grid-cols-1">
                    <h1>Product Name : <strong>{{ this.$route.params.namaProduk }}</strong></h1>
                </div>
                <div class="flex flex-wrap gap-4">
                    <div class="max-h-full" v-for="(thumbnail, index) in thumbnails" :key="index">
                        <label class="cursor-pointer">
                            <div class="relative">
                                <input v-if="thumbnail.isOpen" accept="image/png, image/jpeg"
                                    @change="onFileSelectedThumbnail" :data-index="index" type="file" class="hidden" />
                                <div v-if="thumbnail.isUploading"
                                    class="absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 rounded-lg">
                                    <svg class="w-12 h-12 text-white animate-spin" xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.964 7.964 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                        </path>
                                    </svg>
                                </div>
                                <img class="w-[150px] h-[150px] rounded-lg" :src="thumbnail.url_image"
                                    alt="image description">
                            </div>
                        </label>
                        <button v-if="thumbnail.imageUpload !== null"
                            @click="removeData(thumbnail.id, index, thumbnail.url_image)" type="button"
                            class="w-[150px] mt-2 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Remove</button>
                    </div>
                    <div>
                        <label for="dropzone-file"
                            class="flex flex-col items-center justify-center w-[150px] h-[150px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                    viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M5 12h14m-7 7V5" />
                                </svg>
                            </div>
                            <input @click="addThumbnail" id="dropzone-file" type="button" class="hidden" />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Swal from 'sweetalert2'

export default {
    data() {
        return {
            url_image: require("@/assets/image-1@2x.jpg"),
            tempImage: [],
            thumbnails: [],
            breadCrumb: {
                root: { text: "Home", url: "" },
                child: [
                    { text: "Produk", url: "" },
                    { text: "Edit Thumbnail", url: "" }
                ]
            },
        }
    },
    created() {
        this.fetchData()
    },
    computed: {
        getAddThumbnailProduk() {
            return this.$store.getters.getAddThumbnailProduk
        },
        getListThumbnailProduk() {
            return this.$store.getters.getListThumbnailProduk
        }
    },
    methods: {
        addThumbnail() {
            this.thumbnails.push({ url_image: require("@/assets/image-1@2x.jpg"), imageUpload: null, isUploading: false, isOpen: true })
        },
        onFileSelectedThumbnail(event) {
            const index = event.target.dataset.index
            const file = event.target.files[0]
            if (file) {
                const imageURL = URL.createObjectURL(file)
                this.thumbnails[index].url_image = imageURL
                this.thumbnails[index].imageUpload = file
                this.thumbnails[index].isUploading = true

                this.$store.dispatch('addThumbnailProduk', {
                    id: this.$route.params.idProduk,
                    thumbnailProduk: this.thumbnails[index].imageUpload
                }).then(() => {
                    if (this.getAddThumbnailProduk.status === 200) {
                        this.thumbnails[index].url_image = this.getAddThumbnailProduk.data.thumbnailProduk
                        this.thumbnails[index].imageUpload = file
                        this.thumbnails[index].isUploading = false
                        location.reload()
                    }
                }).catch((error) => {
                    console.error('Upload error:', error)
                    this.thumbnails[index].isUploading = false
                })
            }
        },
        fetchData() {
            this.$store.dispatch('listThumbnailProduk', {
                id: this.$route.params.idProduk
            }).then(() => {
                for (var i = 0; i < this.getListThumbnailProduk.length; i++) {
                    this.thumbnails.push({ id: this.getListThumbnailProduk[i].id, url_image: this.getListThumbnailProduk[i].thumbnailProduk, isUploading: false, isOpen: false })
                }
            }).catch((error) => {
                console.error(error)
            })
        },
        removeData(id, index, url) {
            this.thumbnails[index].isUploading = true
            this.$store.dispatch('deleteThumbnailProduk', {
                id: id,
                thumbnail: url
            }).then(() => {
                this.thumbnails.splice(index, 1)
                this.thumbnails[index].isUploading = false
            }).catch((error) => {
                console.error('Upload error:', error)
            })
        },
    }
}
</script>