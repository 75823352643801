<template>
    <div class="p-4 sm:ml-64">
        <div class="p-4">
            <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div class="grid gap-6 mb-6 md:grid-cols-1">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                            <li class="inline-flex items-center">
                                <a href="#"
                                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    Home
                                </a>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <a href="#"
                                        class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">Pokok
                                        Doa</a>
                                </div>
                            </li>
                            <li aria-current="page">
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <span
                                        class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Create</span>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <form @submit.prevent="handleSubmit">
                    <div class="grid gap-6 mb-6 md:grid-cols-2">
                        <div>
                            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">Title</label>
                            <input v-model="title" type="text" id="first_name"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                placeholder="Title" required />
                        </div>
                        <div>
                            <label class="block mb-2 text-sm font-medium text-gray-900">Schedule</label>
                            <div class="relative my-2.5">
                                <VueDatePicker v-model="schedule" :format="dateFormat"></VueDatePicker>
                            </div>
                        </div>
                    </div>
                    <div class="grid gap-6 mb-6 md:grid-cols-1">
                        <div>
                            <label class="block mb-2 text-sm font-medium text-gray-900">Description</label>
                            <TinyMCE v-model="description"></TinyMCE>
                        </div>
                    </div>
                    <div class="grid gap-6 mb-6 md:grid-cols-1">
                        <input type="submit"
                            class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2"
                            value="submit" />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Swal from 'sweetalert2'

export default defineComponent({
    data() {
        return {
            schedule: ref(new Date()),
            dateFormat: "yyyy-MM-dd",
            type: "HARIAN",
            title: "",
            description: "",
            createdBy: 1,
            isLoadingScreen: false,
        }
    },
    methods: {
        handleSubmit() {
            Swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Create",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoadingScreen = true
                        this.$store.dispatch('createPokokDoa', {
                            judul: this.title,
                            deskripsi: this.description,
                            jadwal: this.schedule.toISOString().split('T')[0],
                            jenis: this.type,
                            createdBy: this.createdBy
                        }).then(() => {
                            Swal.fire({
                                title: "Create Success",
                                text: "Pokok Doa Berhasil di Buat!",
                                icon: "success"
                            })
                        }).catch((error: Error) => {
                            Swal.fire({
                                title: "Error",
                                text: "Gagal membuat pokok doa: " + error,
                                icon: "error"
                            })
                        }).finally(() => {
                            location.reload()
                            this.isLoadingScreen = false
                        })
                    }
                })
        }
    },
});
</script>