<template>
    <div class="p-4 sm:ml-64">
        <div class="p-4">
            <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div class="grid gap-6 mb-6 md:grid-cols-1">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                            <li class="inline-flex items-center">
                                <a href="#"
                                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    {{ breadCrumb.root.text }}
                                </a>
                            </li>
                            <li v-for="items in breadCrumb.child" :key="items.text">
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <a href="#"
                                        class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">{{
                                            items.text
                                        }}</a>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div>
                    <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">Doa Harian</label>
                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead
                                class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" class="px-6 py-3">
                                        Title
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Schedule
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Created By
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Updated By
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Action
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-right">
                                        <button @click="openModalCreate"
                                            class="bg-blue-700 text-white px-2 py-1 rounded-md shadow-md hover:bg-gray-700 transition-colors duration-300">
                                            <svg class="w-6 h-6 text-white-800 dark:text-white" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                fill="currentColor" viewBox="0 0 24 24">
                                                <path fill-rule="evenodd"
                                                    d="M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Zm2 0V2h7a2 2 0 0 1 2 2v6.41A7.5 7.5 0 1 0 10.5 22H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Z"
                                                    clip-rule="evenodd" />
                                                <path fill-rule="evenodd"
                                                    d="M9 16a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm6-3a1 1 0 0 1 1 1v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 1 1 0-2h1v-1a1 1 0 0 1 1-1Z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <span>
                                <div class="fixed inset-0 bg-black z-50" v-if="isModalCreateOpen">
                                    <div aria-hidden="true" class="fixed inset-0 flex items-center justify-center">
                                        <div class="relative p-4 w-[1000px]">
                                            <!-- Modal content -->
                                            <div class="relative bg-white rounded-lg shadow">
                                                <!-- Modal header -->
                                                <div class="flex items-center justify-between p-4 border-b rounded-t">
                                                    <h3 class="text-xl font-semibold text-gray-900">Create
                                                    </h3>
                                                    <button type="button" @click="closeModalCreate"
                                                        class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                                        <svg class="w-3 h-3" aria-hidden="true" fill="none"
                                                            viewBox="0 0 14 14">
                                                            <path stroke="currentColor" stroke-linecap="round"
                                                                stroke-linejoin="round" stroke-width="2"
                                                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                        </svg>
                                                        <span class="sr-only">Close modal</span>
                                                    </button>
                                                </div>
                                                <!-- Modal body -->
                                                <div class="p-4 space-y-4">
                                                    <div>
                                                        <div class="max-h-full">
                                                            <label class="cursor-pointer">
                                                                <div v-if="isLoadingCreate"
                                                                    class="flex items-center justify-center w-full h-32">
                                                                    <svg class="animate-spin h-10 w-10 text-gray-500"
                                                                        xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                        viewBox="0 0 24 24">
                                                                        <circle class="opacity-25" cx="12" cy="12"
                                                                            r="10" stroke="currentColor"
                                                                            stroke-width="4"></circle>
                                                                        <path class="opacity-75" fill="currentColor"
                                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4v4a4 4 0 00-4 4H6v4.291zM20 12a8 8 0 01-8 8v-4a4 4 0 004-4h4zm-2-5.709A8.001 8.001 0 0112 20V16a4 4 0 004-4h4V6.291z">
                                                                        </path>
                                                                    </svg>
                                                                </div>
                                                                <form>
                                                                    <div class="mb-6">
                                                                        <div class="grid gap-6 mb-6 md:grid-cols-2">
                                                                            <div>
                                                                                <label for="first_name"
                                                                                    class="block mb-2 text-sm font-medium text-gray-900">Title</label>
                                                                                <input v-model="form.title" type="text"
                                                                                    id="first_name"
                                                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                                                                    placeholder="Title" required />
                                                                            </div>
                                                                            <div>
                                                                                <label
                                                                                    class="block mb-2 text-sm font-medium text-gray-900">Schedule</label>
                                                                                <div class="relative my-2.5">
                                                                                    <VueDatePicker
                                                                                        v-model="form.schedule"
                                                                                        :format="dateFormat">
                                                                                    </VueDatePicker>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="grid gap-6 mb-6 md:grid-cols-1">
                                                                            <div>
                                                                                <label
                                                                                    class="block mb-2 text-sm font-medium text-gray-900">Description</label>
                                                                                <TinyMCE v-model="form.description">
                                                                                </TinyMCE>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Modal footer -->
                                                <div class="flex items-center p-4 border-t rounded-b">
                                                    <span>
                                                        <button @click="submitCreate" type="button"
                                                            class="text-white bg-blue-800 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-400 dark:hover:bg-gray-400 dark:focus:ring-gray-400">
                                                            Create
                                                        </button>
                                                    </span>
                                                    <button @click="closeModalCreate" type="button"
                                                        class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                        Decline
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <tbody>
                                <tr class="bg-white  dark:bg-gray-800" v-for="item in getListPokokDoa.content"
                                    :key="item.id">
                                    <td scope="row"
                                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {{ item.judul }}
                                    </td>
                                    <td class="px-6 py-4">
                                        {{ formatDate(item.jadwal) }}
                                    </td>
                                    <td class="px-6 py-4">
                                        {{ item.createdBy }}
                                    </td>
                                    <td class="px-6 py-4">
                                        {{ item.updatedBy }}
                                    </td>
                                    <td class="flex px-6 py-4">
                                        <span class="mr-2">
                                            <button @click="openModalUpdate(item.id)"
                                                class="block text-white bg-white-700  font-medium rounded-lg text-sm py-2.5 text-center"
                                                type="button">
                                                <span>
                                                    <svg class="w-6 h-6 text-gray-800 dark:text-white"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                        height="24" fill="currentColor" viewBox="0 0 24 24">
                                                        <path fill-rule="evenodd"
                                                            d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z"
                                                            clip-rule="evenodd" />
                                                        <path fill-rule="evenodd"
                                                            d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z"
                                                            clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </button>
                                            <span>
                                                <div class="fixed inset-0 bg-black z-50" v-if="isModalUpdateOpen">
                                                    <div aria-hidden="true"
                                                        class="fixed inset-0 flex items-center justify-center">
                                                        <div class="relative p-4 w-[1000px]">
                                                            <!-- Modal content -->
                                                            <div class="relative bg-white rounded-lg shadow">
                                                                <!-- Modal header -->
                                                                <div
                                                                    class="flex items-center justify-between p-4 border-b rounded-t">
                                                                    <h3 class="text-xl font-semibold text-gray-900">
                                                                        Update
                                                                    </h3>
                                                                    <button type="button" @click="closeModalUpdate"
                                                                        class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                                                        <svg class="w-3 h-3" aria-hidden="true"
                                                                            fill="none" viewBox="0 0 14 14">
                                                                            <path stroke="currentColor"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" stroke-width="2"
                                                                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                                        </svg>
                                                                        <span class="sr-only">Close modal</span>
                                                                    </button>
                                                                </div>
                                                                <!-- Modal body -->
                                                                <div class="p-4 space-y-4">
                                                                    <div>
                                                                        <div class="max-h-full">
                                                                            <label class="cursor-pointer">
                                                                                <div v-if="isLoadingUpdate"
                                                                                    class="flex items-center justify-center w-full h-32">
                                                                                    <svg class="animate-spin h-10 w-10 text-gray-500"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        fill="none" viewBox="0 0 24 24">
                                                                                        <circle class="opacity-25"
                                                                                            cx="12" cy="12" r="10"
                                                                                            stroke="currentColor"
                                                                                            stroke-width="4"></circle>
                                                                                        <path class="opacity-75"
                                                                                            fill="currentColor"
                                                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4v4a4 4 0 00-4 4H6v4.291zM20 12a8 8 0 01-8 8v-4a4 4 0 004-4h4zm-2-5.709A8.001 8.001 0 0112 20V16a4 4 0 004-4h4V6.291z">
                                                                                        </path>
                                                                                    </svg>
                                                                                </div>
                                                                                <form>
                                                                                    <div class="mb-6">
                                                                                        <div
                                                                                            class="grid gap-6 mb-6 md:grid-cols-2">
                                                                                            <div>
                                                                                                <label for="first_name"
                                                                                                    class="block mb-2 text-sm font-medium text-gray-900">Title</label>
                                                                                                <input
                                                                                                    v-model="form.title"
                                                                                                    type="text"
                                                                                                    id="first_name"
                                                                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                                                                                    placeholder="Title"
                                                                                                    required />
                                                                                            </div>
                                                                                            <div>
                                                                                                <label
                                                                                                    class="block mb-2 text-sm font-medium text-gray-900">Schedule</label>
                                                                                                <div
                                                                                                    class="relative my-2.5">
                                                                                                    <VueDatePicker
                                                                                                        v-model="form.schedule"
                                                                                                        :format="dateFormat">
                                                                                                    </VueDatePicker>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="grid gap-6 mb-6 md:grid-cols-1">
                                                                                            <div>
                                                                                                <label
                                                                                                    class="block mb-2 text-sm font-medium text-gray-900">Description</label>
                                                                                                <TinyMCE
                                                                                                    v-model="form.description">
                                                                                                </TinyMCE>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Modal footer -->
                                                                <div class="flex items-center p-4 border-t rounded-b">
                                                                    <span>
                                                                        <button @click="submitUpdate" type="button"
                                                                            class="text-white bg-blue-800 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-400 dark:hover:bg-gray-400 dark:focus:ring-gray-400">
                                                                            Update
                                                                        </button>
                                                                    </span>
                                                                    <button @click="closeModalUpdate" type="button"
                                                                        class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                                        Decline
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </span>
                                        <span>
                                            <button @click="submitDelete(item.id)"
                                                class="block text-white bg-white-700  font-medium rounded-lg text-sm py-2.5 text-center"
                                                type="button">
                                                <span>
                                                    <svg class="w-6 h-6 text-gray-800 dark:text-white"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                        height="24" fill="currentColor" viewBox="0 0 24 24">
                                                        <path fill-rule="evenodd"
                                                            d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                                            clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <nav class="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
                        aria-label="Table navigation">
                        <span
                            class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">Showing
                            <span class="font-semibold text-gray-900 dark:text-white">
                                {{ (pageInfo.pageNumber - 1) * pageInfo.pageSize + 1 }} -
                                {{ Math.min(pageInfo.pageNumber * pageInfo.pageSize, pageInfo.totalElements) }}
                            </span> of <span class="font-semibold text-gray-900 dark:text-white">{{
                                pageInfo.totalElements
                                }}</span></span>
                        <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                            <li v-if="this.pageInfo.pageNumber > 1"> <a href="#" @click="goToPreviousPage"
                                    class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                    Previous</a>
                            </li>
                            <li v-for="pageNumber in visiblePages" :key="pageNumber">
                                <a href="#" aria-current="page" @click="goToPage(pageNumber)"
                                    :class="{ 'bg-blue-700 text-white': pageNumber === pageInfo.pageNumber }"
                                    class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{
                                        pageNumber }}</a>
                            </li>
                            <li>
                                <a href="#" @click="goToNextPage"
                                    class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="my-5">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">Doa Khusus</label>
                    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead
                                class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" class="px-6 py-3">
                                        Title
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Created By
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Updated By
                                    </th>
                                    <th scope="col" class="px-6 py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-white  dark:bg-gray-800" v-for="item in getListPokokDoaKhusus.content"
                                    :key="item.id">
                                    <td scope="row"
                                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {{ item.judul }}
                                    </td>
                                    <td class="px-6 py-4">
                                        {{ item.createdBy }}
                                    </td>
                                    <td class="px-6 py-4">
                                        {{ item.updatedBy }}
                                    </td>
                                    <td class="px-6 py-4">
                                        <span class="mr-2">
                                            <button @click="openModalUpdateKhusus(item.id)"
                                                class="block text-white bg-white-700  font-medium rounded-lg text-sm py-2.5 text-center"
                                                type="button">
                                                <span>
                                                    <svg class="w-6 h-6 text-gray-800 dark:text-white"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                                                        height="24" fill="currentColor" viewBox="0 0 24 24">
                                                        <path fill-rule="evenodd"
                                                            d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z"
                                                            clip-rule="evenodd" />
                                                        <path fill-rule="evenodd"
                                                            d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z"
                                                            clip-rule="evenodd" />
                                                    </svg>
                                                </span>
                                            </button>
                                            <span>
                                                <div class="fixed inset-0 bg-black z-50" v-if="isModalUpdateOpenKhusus">
                                                    <div aria-hidden="true"
                                                        class="fixed inset-0 flex items-center justify-center">
                                                        <div class="relative p-4 w-[1000px]">
                                                            <!-- Modal content -->
                                                            <div class="relative bg-white rounded-lg shadow">
                                                                <!-- Modal header -->
                                                                <div
                                                                    class="flex items-center justify-between p-4 border-b rounded-t">
                                                                    <h3 class="text-xl font-semibold text-gray-900">
                                                                        Update
                                                                    </h3>
                                                                    <button type="button"
                                                                        @click="closeModalUpdateKhusus"
                                                                        class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                                                        <svg class="w-3 h-3" aria-hidden="true"
                                                                            fill="none" viewBox="0 0 14 14">
                                                                            <path stroke="currentColor"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round" stroke-width="2"
                                                                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                                        </svg>
                                                                        <span class="sr-only">Close modal</span>
                                                                    </button>
                                                                </div>
                                                                <!-- Modal body -->
                                                                <div class="p-4 space-y-4">
                                                                    <div>
                                                                        <div class="max-h-full">
                                                                            <label class="cursor-pointer">
                                                                                <div v-if="isLoadingUpdateKhusus"
                                                                                    class="flex items-center justify-center w-full h-32">
                                                                                    <svg class="animate-spin h-10 w-10 text-gray-500"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        fill="none" viewBox="0 0 24 24">
                                                                                        <circle class="opacity-25"
                                                                                            cx="12" cy="12" r="10"
                                                                                            stroke="currentColor"
                                                                                            stroke-width="4"></circle>
                                                                                        <path class="opacity-75"
                                                                                            fill="currentColor"
                                                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4v4a4 4 0 00-4 4H6v4.291zM20 12a8 8 0 01-8 8v-4a4 4 0 004-4h4zm-2-5.709A8.001 8.001 0 0112 20V16a4 4 0 004-4h4V6.291z">
                                                                                        </path>
                                                                                    </svg>
                                                                                </div>
                                                                                <form>
                                                                                    <div class="mb-6">
                                                                                        <div
                                                                                            class="grid gap-6 mb-6 md:grid-cols-1">
                                                                                            <div>
                                                                                                <label for="first_name"
                                                                                                    class="block mb-2 text-sm font-medium text-gray-900">Title</label>
                                                                                                <input
                                                                                                    v-model="form.title"
                                                                                                    type="text"
                                                                                                    id="first_name"
                                                                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                                                                                    placeholder="Title"
                                                                                                    required />
                                                                                            </div>
                                                                                            <!-- <div>
                                                                                                <label
                                                                                                    class="block mb-2 text-sm font-medium text-gray-900">Schedule</label>
                                                                                                <div
                                                                                                    class="relative my-2.5">
                                                                                                    <VueDatePicker
                                                                                                        v-model="form.schedule"
                                                                                                        :format="dateFormat">
                                                                                                    </VueDatePicker>
                                                                                                </div>
                                                                                            </div> -->
                                                                                        </div>
                                                                                        <div
                                                                                            class="grid gap-6 mb-6 md:grid-cols-1">
                                                                                            <div>
                                                                                                <label
                                                                                                    class="block mb-2 text-sm font-medium text-gray-900">Description</label>
                                                                                                <TinyMCE
                                                                                                    v-model="form.description">
                                                                                                </TinyMCE>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Modal footer -->
                                                                <div class="flex items-center p-4 border-t rounded-b">
                                                                    <span>
                                                                        <button @click="submitUpdateKhusus"
                                                                            type="button"
                                                                            class="text-white bg-blue-800 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-400 dark:hover:bg-gray-400 dark:focus:ring-gray-400">
                                                                            Update
                                                                        </button>
                                                                    </span>
                                                                    <button @click="closeModalUpdateKhusus"
                                                                        type="button"
                                                                        class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                                        Decline
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import Swal from 'sweetalert2'
import { format } from 'date-fns'
import { id } from 'date-fns/locale'

export default {
    data() {
        return {
            breadCrumb: {
                root: { text: "Home", url: "" },
                child: [
                    { text: "Prayer", url: "" },
                    { text: "List", url: "" }
                ]
            },
            pageInfo: {
                totalPages: 0,
                pageNumber: 0,
                visiblePages: 5,
                pageSize: 5,
                totalElements: 0,
            },
            form: {
                id: null,
                title: "",
                description: "",
                schedule: ref(new Date()),
            },
            dateFormat: "yyyy-MM-dd",
            isLoadingCreate: false,
            isLoadingUpdate: false,
            isLoadingUpdateKhusus: false,
            isModalCreateOpen: false,
            isModalUpdateOpen: false,
            isModalUpdateOpenKhusus: false,
            isLoadingScreen: false,
            agensi: null,
        }
    },
    created() {
        var parameters = this.$route.params
        this.fetchDataHarian(parameters.agensi, parseInt(parameters.pageNumber))
        this.fetchDataKhusus(parameters.agensi, 1)
    },
    computed: {
        getListPokokDoa() {
            return this.$store.getters.getListPokokDoa
        },
        getListPokokDoaKhusus() {
            return this.$store.getters.getListPokokDoaKhusus
        },
        getDetailPokokDoa() {
            return this.$store.getters.getDetailPokokDoa
        },
        visiblePages() {
            const activePage = this.pageInfo.pageNumber;
            const totalVisiblePages = this.pageInfo.visiblePages;
            const totalPages = this.pageInfo.totalPages;

            let startPage = activePage - Math.floor(totalVisiblePages / 2)
            startPage = Math.max(startPage, 1)
            let endPage = startPage + totalVisiblePages - 1
            endPage = Math.min(endPage, totalPages)

            if (endPage === totalPages && totalPages - totalVisiblePages + 1 > 0) {
                startPage = totalPages - totalVisiblePages + 1
            }

            let visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)

            if (endPage < totalPages) {
                visiblePages.push(endPage + 1)
            }

            return visiblePages;
        }
    },
    methods: {
        formatDate(date) {
            return format(new Date(date), 'EEEE, dd MMMM yyyy', { locale: id });
        },
        openModalCreate() {
            this.isModalCreateOpen = true
        },
        closeModalCreate() {
            this.form.title = ""
            this.form.description = ""
            this.form.schedule = ref(new Date())
            this.isModalCreateOpen = false
        },
        async openModalUpdate(id) {
            this.form.id = id
            await this.$store.dispatch('detailPokokDoa', {
                id: this.form.id = id
            }).then(() => {
                this.isLoadingUpdate = true
                this.form.title = this.getDetailPokokDoa.judul
                this.form.schedule = this.getDetailPokokDoa.jadwal
                this.form.description = this.getDetailPokokDoa.deskripsi
            }).finally(() => {
                this.isLoadingUpdate = false
            })
            this.isModalUpdateOpen = true
        },
        closeModalUpdate() {
            this.form.title = ""
            this.form.description = ""
            this.form.schedule = ref(new Date())
            this.isModalUpdateOpen = false
        },
        async openModalUpdateKhusus(id) {
            this.form.id = id
            await this.$store.dispatch('detailPokokDoa', {
                id: this.form.id = id
            }).then(() => {
                this.isLoadingUpdateKhusus = true
                this.form.title = this.getDetailPokokDoa.judul
                this.form.schedule = this.getDetailPokokDoa.jadwal
                this.form.description = this.getDetailPokokDoa.deskripsi
            }).finally(() => {
                this.isLoadingUpdateKhusus = false
            })
            this.isModalUpdateOpenKhusus = true
        },
        closeModalUpdateKhusus() {
            this.form.title = ""
            this.form.description = ""
            this.form.schedule = ref(new Date())
            this.isModalUpdateOpenKhusus = false
        },
        async fetchDataKhusus(agensi, pageNumber) {
            this.pageInfo.pageNumber = pageNumber
            this.agensi = agensi

            await this.$store.dispatch('listPokokDoaKhusus', {
                index: (this.pageInfo.pageNumber - 1),
                limit: this.pageInfo.pageSize,
                jenis: "KHUSUS",
            }).then(() => {
                this.isLoadingScreen = true
                this.pageInfo.totalElements = this.getListPokokDoa.totalElements
                this.pageInfo.totalPages = this.getListPokokDoa.totalPages
            }).finally(() => {
                this.isLoadingScreen = false
            })
        },
        async fetchDataHarian(agensi, pageNumber) {
            this.pageInfo.pageNumber = pageNumber
            this.agensi = agensi

            await this.$store.dispatch('listPokokDoa', {
                index: (this.pageInfo.pageNumber - 1),
                limit: this.pageInfo.pageSize,
                jenis: "HARIAN",
            }).then(() => {
                this.isLoadingScreen = true
                this.pageInfo.totalElements = this.getListPokokDoa.totalElements
                this.pageInfo.totalPages = this.getListPokokDoa.totalPages
            }).finally(() => {
                this.isLoadingScreen = false
            })

        },
        async goToPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.pageInfo.totalPages) {
                await this.fetchDataHarian(this.agensi, pageNumber)
                this.tempRouteList(this.agensi, pageNumber)

            }
        },
        async goToNextPage() {
            const nextPageNumber = this.pageInfo.pageNumber + 1;
            if (nextPageNumber <= this.pageInfo.totalPages) {
                await this.fetchDataHarian(this.agensi, nextPageNumber)
                this.tempRouteList(this.agensi, nextPageNumber)
            }
        },
        async goToPreviousPage() {
            const previousPageNumber = this.pageInfo.pageNumber - 1
            if (previousPageNumber >= 1) {
                await this.fetchDataHarian(this.agensi, previousPageNumber)
                this.tempRouteList(this.agensi, previousPageNumber)
            }
        },
        tempRouteList(agensi, pageNumber) {
            this.$router.push({ name: 'list-pokok-doa', params: { agensi: agensi, pageNumber: pageNumber } })
        },
        submitCreate() {
            Swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Create",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoadingCreate = true
                        this.$store.dispatch('createPokokDoa', {
                            judul: this.form.title,
                            deskripsi: this.form.description,
                            jadwal: format(new Date(this.form.schedule), 'yyyy-MM-dd'),
                            jenis: "HARIAN",
                        }).then(() => {
                            Swal.fire({
                                title: "Create Success",
                                text: "Pokok Doa Berhasil di Buat!",
                                icon: "success"
                            })
                        }).catch((error) => {
                            Swal.fire({
                                title: "Error",
                                text: "Gagal membuat pokok doa: " + error,
                                icon: "error"
                            })
                        }).finally(() => {
                            location.reload()
                            this.isLoadingCreate = false
                        })
                    }
                })
        },
        submitUpdate() {
            Swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Update",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoadingUpdate = true
                        this.$store.dispatch('editPokokDoa', {
                            id: this.form.id,
                            judul: this.form.title,
                            deskripsi: this.form.description,
                            jadwal: format(new Date(this.form.schedule), 'yyyy-MM-dd'),
                            jenis: "HARIAN",
                        }).then(() => {
                            Swal.fire({
                                title: "Update Success",
                                text: "Pokok Doa Berhasil diubah!",
                                icon: "success"
                            })
                        }).catch((error) => {
                            Swal.fire({
                                title: "Error",
                                text: "Gagal mengubah pokok doa: " + error,
                                icon: "error"
                            })
                        }).finally(() => {
                            location.reload()
                            this.isLoadingUpdate = false
                        })
                    }
                })
        },
        submitUpdateKhusus() {
            Swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Update",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoadingUpdateKhusus = true
                        this.$store.dispatch('editPokokDoa', {
                            id: this.form.id,
                            judul: this.form.title,
                            deskripsi: this.form.description,
                            jadwal: format(new Date(this.form.schedule), 'yyyy-MM-dd'),
                            jenis: "KHUSUS",
                        }).then(() => {
                            Swal.fire({
                                title: "Update Success",
                                text: "Pokok Doa Berhasil diubah!",
                                icon: "success"
                            })
                        }).catch((error) => {
                            Swal.fire({
                                title: "Error",
                                text: "Gagal mengubah pokok doa: " + error,
                                icon: "error"
                            })
                        }).finally(() => {
                            location.reload()
                            this.isLoadingUpdateKhusus = false
                        })
                    }
                })
        },
        submitDelete(id) {
            Swal
                .fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Delete",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.isLoadingScreen = true
                        this.$store.dispatch('deletePokokDoa', {
                            id: id
                        }).then(() => {
                            Swal.fire({
                                title: "Delete Success",
                                text: "Pokok Doa Berhasil dihapus!",
                                icon: "success"
                            })
                        }).catch((error) => {
                            Swal.fire({
                                title: "Error",
                                text: "Gagal menghapus pokok doa: " + error,
                                icon: "error"
                            })
                        }).finally(() => {
                            location.reload()
                            this.isLoadingScreen = false
                        })
                    }
                })
        },
    },
}
</script>